import React, { useState, useEffect, useCallback } from "react";
import { Table, Col, Row, Button, Container } from "react-bootstrap";
import "./Invoices.css";
import { HiEye, HiOutlineTrash } from "react-icons/hi";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { errorToast } from "../../helpers/toastFunction";

import { getInvoices } from "../../services/invoices";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import useDebounce from "../../hooks/useDebounce";
import { useSearchParams } from "react-router-dom";

export default function Invoices() {
  const [tasksCount, setTasksCount] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [actualPage, setActualPage] = useState(1);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [identificationFilter, setIdentificationFilter] = useState(undefined);

  const navigate = useNavigate();

  const debouncedSearchIdentification = useDebounce(identificationFilter, 500);

  const handleGetInvoices = React.useCallback(async () => {
    try {
      const response = await getInvoices({
        order: "ASC",
        page: actualPage,
        take: limit,
        identification: debouncedSearchIdentification,
      });
      setInvoices(response.data);
      setTasksCount(response.meta.itemCount);
      setTotalPages(response.meta.pageCount);
    } catch (error) {
      return errorToast("Error al obtener las facturas");
    }
  }, [actualPage, limit, debouncedSearchIdentification]);

  useEffect(() => {
    handleGetInvoices();
  }, [handleGetInvoices]);

  const openDocumentHandler = async (data) => {
    // // const response = await getSignedUrl(data);
    // window.open(response.signedUrl[0]);
  };

  const pageHandler = (action) => {
    if (action === "next") {
      if (actualPage < totalPages) {
        setActualPage(actualPage + 1);
      }
    } else {
      if (actualPage > 1) {
        setActualPage(actualPage - 1);
      }
    }
  };

  const filterHandler = (data) => {
    const value = data.e.target.value;
    const type = data.type;
    if (type === "search" && value.length > 0) {
      searchParams.set("search", value);
      setIdentificationFilter(value);
    } else if (type === "search" && value.length === 0) {
      setIdentificationFilter(undefined);
      searchParams.has("search") && searchParams.delete("search");
    }
    setSearchParams(searchParams);
    setActualPage(1);
  };

  const clearFilter = () => {
    setIdentificationFilter("");
    setSearchParams("");
  };

  const limitHoursDate = new Date().getTime() - 24 * 60 * 60 * 1000;

  return (
    <>
      <Container fluid>
        <Row className="mt-3">
          <Col className="col-12">
            <div className={`clearfix mt-3 mb- containerTitle`}>
              <span className="tittle ml-4">Facturas</span>
            </div>
          </Col>
          <Col className="col-3 mt-3">
            <Button type="submit" onClick={() => navigate("/nueva-factura")}>
              Añadir factura
            </Button>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xl={2}>
            <Form>
              <Form.Group className="">
                <Form.Control
                  value={identificationFilter}
                  onChange={(e) => filterHandler({ e, type: "search" })}
                  placeholder="DNI, CIF, NIE"
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xl={2}>
            {Object.keys(Object.fromEntries([...searchParams])).length > 0 && (
              <Button variant="secondary" onClick={() => clearFilter()}>
                Borrar filtros
              </Button>
            )}
          </Col>
        </Row>
        <div className="amount-summary">
          <div className="amount-summary__single-item">
            <h6 className="mt-4">
              Nº de Facturas:<span> {tasksCount}</span>
            </h6>
          </div>
        </div>
        <Row className="mt-2">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th style={{ width: "5%" }}>Estado</th>
                <th style={{ width: "10%" }}>Fecha</th>
                <th style={{ width: "10%" }}>CIF, DNI, NIE</th>
                <th style={{ width: "5%" }}>Nª Factura</th>
                <th style={{ width: "10%" }}>Total a pagar</th>
                <th style={{ width: "10%" }}>Tienda </th>
                <th style={{ width: "10%" }}>Sociedad</th>
                <th style={{ width: "10%" }}>Metodo de pago</th>
                <th style={{ width: "5%" }}>Acciones</th>
              </tr>
            </thead>
            <tbody style={{ width:"100vw" }}>
              {invoices.map((invoice) => (
                <tr
                  key={invoice.id}
                  data-toggle="collapse"
                  data-target=".multi-collapse1"
                  aria-controls="multiCollapseExample1"
                  className="clickable cursor_pointer"
                >
                  <td>{invoice.status.name}</td>
                  <td>{new Date(invoice.createdAt).toLocaleDateString()}</td>
                  <td>{invoice.providerIdentification}</td>
                  <td>{invoice.invoiceNumber}</td>
                  <td>{invoice.totalAmount}</td>
                  <td>{invoice.local.nombre}</td>
                  <td>{invoice.society.nombre}</td>
                  <td>{invoice.paymentType.name}</td>
                  <td>
                    {new Date(invoice.createdAt).getTime >= limitHoursDate ? (
                      <HiOutlineTrash size={20} color={"RED"}/>
                    ) : (
                      <HiOutlineTrash size={20} color={"GREY"} disabled/>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </Container>
      {invoices && invoices.length > 0 && (
        <nav>
          <div className="pagination__container">
            <div
              onClick={() => pageHandler("back")}
              className="pagination__element"
            >
              {actualPage !== 1 && (
                <AiOutlineArrowLeft
                  title="Anterior"
                  size={20}
                  color={"#E88A00"}
                />
              )}
            </div>
            <div className="pagination__element">
              <p className="pagination__text">
                {actualPage}/{totalPages}
              </p>
            </div>

            <div
              onClick={() => pageHandler("next")}
              className="pagination__element"
            >
              {actualPage !== totalPages && (
                <AiOutlineArrowRight
                  title="Siguiente"
                  size={20}
                  color={"#E88A00"}
                />
              )}
            </div>
          </div>
        </nav>
      )}
    </>
  );
}
