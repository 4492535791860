import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import "./CustomAlert.css";

const CustomAlert = (props) => {
  const { tittle, message, variant, showAlertHandler } = props;

  useEffect(() => {
    setTimeout(() => {
      showAlertHandler();
    }, 7000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="custom_container">
      <Alert
        className="custom_position"
        variant={variant}
        onClose={() => showAlertHandler(false)}
        dismissible
      >
        <Alert.Heading>{tittle}</Alert.Heading>
        <hr />
        <p>{message}</p>
      </Alert>
    </div>
  );
};

export default CustomAlert;
