import { config } from "../config/environment.config";
import { axiosInstance } from "./BaseService";

export const createIncidence = async (formData) => {
  try {
    return axiosInstance.post(`${config.API_HOST}/incidence`, formData);
  } catch (error) {}
};

export const deleteIncidence = async (taskId) => {
  try {
    return axiosInstance.delete(`${config.API_HOST}/incidence/${taskId}`);
  } catch (error) {}
};

export const updateIncidence = async (data) => {
  try {
    return axiosInstance.patch(`${config.API_HOST}/incidence/${data.id}`, {
      review: data.review,
    });
  } catch (error) {}
};
