import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import "./ModalEdition.css";
import { Controller, useForm } from "react-hook-form";
import {
  getTaxesTypes,
  getVehicleOrigin,
  post,
  updateTask,
} from "../../services/axiosCall";
import Select from "react-select";
import validationCIF from "../../helpers/validationCIF";
import validationDNI from "../../helpers/validationDNI";
import validationNIE from "../../helpers/validationNIE";
import validationIBAN from "../../helpers/validationIBAN";
import OriginType from "../../constants/origin";
import { removeNullFields } from "../../helpers/removeNullFields";
import { errorToast, successToast } from "../../helpers/toastFunction";
import validationPostalCode from "../../helpers/validationPostalCode";
import { useUserContext } from "contexts/UserContext";

const ModalEdition = ({ show, handleClose, task, type }) => {
  const { user } = useUserContext();
  const [taxesTypes, setTaxesTypes] = useState([]);
  const [brandSelected, setBrandSelected] = useState(task.marca);
  const [brandFilter, setBrandFilter] = useState(null);
  const [modelFilter, setModelFilter] = useState(null);
  const [vehicleOrigin, setVehicleOrigin] = useState([]);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    (async () => {
      try {
        const query = `models?brand=${brandSelected}`;
        const response = await post("/api/jato", { query });
  
        const modelosFiltro = response?.map((item, i) => {
          return { label: item.name, value: i };
        });
        modelosFiltro.push({ label: "Modelo no encontrado", value: "other" });
        setModelFilter(modelosFiltro);
      } catch (error) {
      }
    })()
  }, [brandSelected]);

  useEffect(() => {
    setLoading(false);
    reset({
      titular: task.titular || null,
      importe: task.importe || null,
      ajuste: task.ajuste || null,
      matricula: task.matricula || null,
      bastidor: task.bastidor || null,
      numeroIdentificacion: task.numeroIdentificacion || null,
      direccion: `${task.direccion}` || null,
      iban: task.iban || null,
    });

    (async () => {
      if (type === "impuestos") {
        const taxesTypesResponse = await getTaxesTypes();
        setTaxesTypes(taxesTypesResponse);
      }
  
      if (type === "marca") {
        const query = "brands";
        const response = await post("/api/jato", { query });
        const marcasFiltro = response?.map((item, i) => {
          return { label: item.brand, value: i };
        });
        setBrandFilter(marcasFiltro);
      }
      if (type === "origen") {
        const vehicleOriginResponse = await getVehicleOrigin();
        setVehicleOrigin(vehicleOriginResponse);
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let body = {};
      if (
        data.impuestos ||
        data.marca ||
        data.modelo ||
        data.origin ||
        data.local ||
        data.society
      ) {
        const filteredData = {
          impuestos: data.impuestos?.value || null,
          marca: data.marca?.label || null,
          modelo: data.modelo?.label || null,
          origin: data.origin?.value || null,
          local: data.local?.value || null,
          society: data.society?.value || null,
        };
        const dataFiltered = removeNullFields(filteredData);
        body = { ...dataFiltered, id: task.id };
      } else {
        body = { ...data, id: task.id };
      }

      const result = await updateTask(body);

      if (result) {
        successToast(
          "La modificación de los datos se ha completado correctamente."
        );
      }
      handleClose();
    } catch (error) {
      const errorMessages = {
        409: (
          <>
            <p className="toast-title">No es posible guardar el cambio</p>
            <p className="toast-text">
              Esta matrícula y DNI ya están vinculados a otra gestión
            </p>
          </>
        ),
        default:
          "Ha habido un problema con la edición. Vuelva a intentarlo más tarde y en caso de que el error persista contacte con su administrador.",
      };
      errorToast(errorMessages[error.response.status] || errorMessages.default);
    } finally {
      setLoading(false);
   
    }
  };

  const selectImpuestos = () => {
    const response = taxesTypes;
    if (task.origin.value === OriginType.PRIVATE) {
      return [{ label: "REBU", value: 4 }];
    }
    return response;
  };

  const maxDate = (value) => {
    const date = new Date(value);
    const today = new Date();
    return date <= today;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="modal_header">
          <Modal.Title>{`Editar ${type}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {type === "impuestos" && (
              <Row className="mt-4">
                <h6 title="Seleccion tipo de impuesto">Tipo de impuesto:</h6>
                <Col>
                  <Controller
                    name="impuestos"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione impuesto"
                        {...field}
                        options={selectImpuestos()}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.impuestos?.type === "required" && (
                    <span className="error">Seleccione impuesto</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "importe" && (
              <Row className="mt-4">
                <h6 title="Introduza importe">Importe del vehículo:</h6>
                <Col className="my-1">
                  <input
                    type="number"
                    className={`form-control ${
                      errors.importe && "is-invalid"
                    } `}
                    {...register("importe", { required: true })}
                  />
                  {errors.importe?.type === "required" && (
                    <span className="error">
                      Introduzca importe del vehículo
                    </span>
                  )}
                </Col>
              </Row>
            )}

            {type === "ajuste" && (
              <Row className="mt-4">
                <h6 title="Introduza ajuste">Importe de ajuste:</h6>
                <Col className="my-1">
                  <input
                    type="number"
                    className={`form-control ${errors.ajuste && "is-invalid"} `}
                    {...register("ajuste", { required: true })}
                  />
                  {errors.ajuste?.type === "required" && (
                    <span className="error">
                      Introduzca el importe de ajuste
                    </span>
                  )}
                </Col>
              </Row>
            )}

            {type === "marca" && (
              <Row>
                <Col>
                  <Controller
                    name="marca"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        onBlur={setBrandSelected(field.value?.label)}
                        placeholder="Marca"
                        {...field}
                        options={brandFilter}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.marca?.type === "required" && (
                    <span className="error">Seleccione marca del vehículo</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "modelo" && (
              <Row>
                <Col>
                  <Controller
                    name="modelo"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        placeholder="Modelo"
                        {...field}
                        options={modelFilter}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.modelo?.type === "required" && (
                    <span className="error">
                      Seleccione modelo del vehículo
                    </span>
                  )}
                </Col>
              </Row>
            )}
            {type === "matricula" && (
              <Row>
                <Col>
                  <input
                    className={`form-control ${
                      errors.matricula && "is-invalid"
                    } `}
                    placeholder="Matrícula"
                    {...register("matricula", {
                      required: true,
                      pattern: {
                        value: /^\d{4}?[ -]*([A-Z]{3})$/i,
                      },
                    })}
                  />
                  {errors.matricula?.type === "required" && (
                    <span className="error">
                      Introduzca número de matrícula
                    </span>
                  )}
                  {errors.matricula &&
                    errors.matricula?.type !== "required" && (
                      <span className="error">La matrícula es incorrecta.</span>
                    )}
                </Col>
              </Row>
            )}

            {type === "vehiculoFormaPago" && (
              <Row>
                <Col>
                  <input
                    className={`form-control ${
                      errors.vehiculoFormaPago && "is-invalid"
                    } `}
                    placeholder="Matrícula del vehículo entregado como forma de pago"
                    {...register("vehiculoFormaPago", {
                      required: true,
                      pattern: {
                        value: /^\d{4}?[ -]*([A-Z]{3})$/i,
                      },
                    })}
                  />
                  {errors.vehiculoFormaPago?.type === "required" && (
                    <span className="error">
                      Introduzca número de matrícula
                    </span>
                  )}
                  {errors.vehiculoFormaPago &&
                    errors.vehiculoFormaPago?.type !== "required" && (
                      <span className="error">
                        La matrícula del vehículo entregado como forma de pago
                        es incorrecta.
                      </span>
                    )}
                </Col>
              </Row>
            )}
            {type === "bastidor" && (
              <Row>
                <Col>
                  <input
                    title=""
                    className={`form-control ${
                      errors.bastidor && "is-invalid"
                    } `}
                    placeholder="Bastidor"
                    {...register("bastidor", {
                      required: true,
                      pattern: {
                        value: /^\b[(A-H|J-N|P|R-Z|0-9)]{17}\b$/i,
                      },
                    })}
                  />
                  {errors.bastidor?.type === "required" && (
                    <span className="error">
                      Introduzca el número de bastidor
                    </span>
                  )}
                  {errors.bastidor && errors.bastidor?.type !== "required" && (
                    <span className="error">Introduzca un bastidor válido</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "matriculacion" && (
              <Row>
                <Col>
                  <input
                    type="date"
                    className={`form-control ${
                      errors.matriculacion && "is-invalid"
                    } `}
                    placeholder="Fecha 1ª matriculación"
                    {...register("matriculacion", {
                      required: true,
                      pattern: /^(19[7-9]\d|20\d{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                      validate: maxDate,
                      
                    })}
                  />
                  {errors.matriculacion?.type === "required" && (
                    <span className="error">
                      Introduzca fecha de la primera matriculación.
                    </span>
                  )}
                    {errors.matriculacion && errors.matriculacion?.type !== "required" && (
                    <span className="error">Introduzca Fecha de la primera matriculación Válida</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "fechaContrato" && (
              <Row>
                <Col>
                  <input
                   
                    type="date"
                    className={`form-control ${
                      errors.fechaContrato && "is-invalid"
                    } `}
                    placeholder="Fecha contrato"
                    {...register("fechaContrato", {
                      required: true,
                      pattern: /^(19[7-9]\d|20\d{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                      validate: maxDate,
                    })}
                  />
                  {errors.fechaContrato?.type === "required" && (
                    <span className="error">
                      Introduzca fecha del contrato.
                    </span>
                  )}
                   {errors.fechaContrato && errors.fechaContrato?.type !== "required" && (
                    <span className="error">Introduzca Fecha del contrato Válida</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "zip" && (
              <Row className="mt-4">
                <Col className="my-1">
                  <input
                    type="text"
                    className={`form-control ${errors.zip && "is-invalid"} `}
                    placeholder="Codigo postal"
                    {...register("zip", {
                      required: true,
                      validate: (value) => validationPostalCode(value)
                    })}
                  />
                  {errors.zip?.type === "required" && (
                    <span className="error">Introduzca el código postal</span>
                  )}
                  {errors.zip && errors.zip?.type !== "required" && (
                    <span className="error">
                      Introduzca un código postal válido
                    </span>
                  )}
                </Col>
              </Row>
            )}

            {type === "titular" && (
              <Row className="mt-4">
                <Col className="my-1">
                  <input
                    placeholder="Titular"
                    className={`form-control ${
                      errors.titular && "is-invalid"
                    } `}
                    {...register("titular", { required: true })}
                  />
                  {errors.titular?.type === "required" && (
                    <span className="error">Introduzca el titular</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "numeroIdentificacion" && (
              <Row>
                <Col>
                  <input
                    className={`form-control ${
                      errors.numeroIdentificacion && "is-invalid"
                    } `}
                    placeholder={
                      task.origin.value === 3 || task.origin.value === 4
                        ? "CIF"
                        : "DNI/NIE"
                    }
                    {...register("numeroIdentificacion", {
                      required: true,
                      validate: (value) =>
                        task.origin.value === 3 || task.origin.value === 4
                          ? validationCIF(value)
                          : validationDNI(value)
                          ? true
                          : validationNIE(value),
                    })}
                  />
                  {errors.numeroIdentificacion?.type === "required" && (
                    <span className="error">
                      Introduzca número de DNI/NIE/CIF
                    </span>
                  )}
                  {errors.numeroIdentificacion &&
                    errors.numeroIdentificacion?.type !== "required" && (
                      <span className="error">
                        {task.origin.value === 3 || task.origin.value === 4
                          ? "El CIF introducido es incorrecto"
                          : "El DNI/NIE introducido es incorrecto"}
                      </span>
                    )}
                </Col>
              </Row>
            )}
            {type === "origen" && (
              <Row>
                <Col>
                  <Controller
                    name="origin"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione origen del vehículo"
                        {...field}
                        options={vehicleOrigin}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.origin?.type === "required" && (
                    <span className="error">
                      Seleccione origen del vehículo
                    </span>
                  )}
                </Col>
              </Row>
            )}
            {type === "direccion" && (
              <Row>
                <Col>
                  <input
                    className={`form-control ${
                      errors.direccion && "is-invalid"
                    } `}
                    placeholder="Matrícula"
                    {...register("direccion", {
                      required: true,
                    })}
                  />
                  {errors.direccion?.type === "required" && (
                    <span className="error">Introduzca dirección</span>
                  )}
                </Col>
              </Row>
            )}
            {type === "iban" && (
              <Row>
                <Col>
                  <input
                    className={`form-control ${errors.iban && "is-invalid"} `}
                    placeholder="IBAN"
                    {...register("iban", {
                      required: true,
                      validate: (value) => validationIBAN(value),
                    })}
                  />
                  {errors.iban?.type === "required" && (
                    <span className="error">Introduzca número de IBAN</span>
                  )}
                  {errors.iban && errors.iban?.type !== "required" && (
                    <span className="error">
                      El IBAN introducido es incorrecto
                    </span>
                  )}
                </Col>
              </Row>
            )}

            {type === "local" && (
              <Row>
                <Col>
                  <Controller
                    name="local"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione local"
                        {...field}
                        options={user.dealershipOptions}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.local?.type === "required" && (
                    <span className="error">Seleccione concesionario</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "sociedad" && (
              <Row>
                <Col>
                  <Controller
                    name="society"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        placeholder="Seleccione sociedad"
                        {...field}
                        options={user.companiesOptions}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#fe9800",
                            primary25: "#ffc548",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.society?.type === "required" && (
                    <span className="error">
                      Seleccione origen del vehículo
                    </span>
                  )}
                </Col>
              </Row>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {!loading ? (
            <Button className="btn_custom" onClick={handleSubmit(onSubmit)}>
              Guardar
            </Button>
          ) : (
            <Button className="btn_custom" disabled>
              Subiendo...
              <Spinner
                variant="warning"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </form>
  );
};

export default ModalEdition;
