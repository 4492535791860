import axios from "axios";
import { decode } from "jsonwebtoken";

import { config } from "../config/environment.config";
import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
} from "../store/AccessTokenStore";
import { refreshTokens } from "./AuthService";

export const createInstance = (opts = {}) => {
  const instance = axios.create({
    baseURL: config.API_HOST,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    timeout: 25000,
    ...opts,
  });

  instance.interceptors.request.use((config) => {
    const token = getAccessToken();
    
    if (token) {
      const { exp } = decode(token);
      if (new Date(exp * 1000) > Date.now())
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => response.data,
    async (error) => {
      const originalRequest = error.config;
      const refreshToken = getRefreshToken();

      if (
        window.location.pathname !== "/login" &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        if (refreshToken) {
          const { exp } = decode(refreshToken);

          if (new Date(exp * 1000) > Date.now()) {
            originalRequest._retry = true;
            await refreshTokens();
            return instance(originalRequest);
          } else {
            window.location.href = "/login";
            removeTokens();
          }
        } else {
          window.location.href = "/login";
          removeTokens();
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const instance = axios.create({
  baseURL: config.API,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  timeout: 10000,
});

export const axiosInstance = createInstance({
  baseURL: config.API_HOST,
  timeout: 10000,
  reloadOnUnauthorized: false,
});

export const axiosInstanceApi = createInstance({
  baseURL: config.API,
  timeout: 10000,
  reloadOnUnauthorized: false,
});
