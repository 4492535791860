import { axiosInstanceApi } from "./BaseService";

const INITIAL_DATACALL = {
  data_query: "locales_citar",
  data_call: null,
};

export const getFilters = async (dataCall = INITIAL_DATACALL) => {

    return axiosInstanceApi.post(`/api/filtros`, {
      dataCall: dataCall,
    });

};
