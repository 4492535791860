import { useCallback, useEffect, useState } from "react";
import { Col, Form, Row, Container, Spinner, Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";

import "./Domains.css";
import { getDomains, updatePaymentDomain } from "../../services/domain.service";
import Pagination from "../../components/Pagination/Pagination";
import { useUserContext } from "contexts/UserContext";


const Domains = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [selectedTotal, setSelectedTotal] = useState(0);
  const [domains, setDomains] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [actualPage, setActualPage] = useState(searchParams.get("page") || 1);
  const [limit] = useState(50);
  const [domainsCount, setDomainsCount] = useState(null);
  const [societyFilter, setSocietyFilter] = useState(null);
  const [selected, setSelected] = useState([]);
  const [sum, setSum] = useState(0);
  const initialOffset = parseFloat(searchParams.get("offset")) || 0
  const [offset, setOffset] = useState(initialOffset);  

  const getDomainsHandler = useCallback(async () => {
    setLoading(false);
    setSelected([]);
    setDomains(null);
    setSum(0);
    setSelectedTotal(0);
    if (user.id) {
      const response = await getDomains({
        limit: limit,
        offset: offset,
        society: societyFilter,
        pay: 4,
        incidence: [2],
        completed: false,
        userId: user.id,
      });
      let domainReadytoPay = response.data;
      let domainReadySorted = domainReadytoPay.sort(function (a, b) {
        return new Date(a.fechaLimite) - new Date(b.fechaLimite);
      });

      setDomains(domainReadySorted);
      setDomainsCount(response.count);
    }
  }, [limit, offset, societyFilter, user.id]);

  useEffect(() => {
    getDomainsHandler();
  }, [getDomainsHandler, societyFilter]);

  const handleCompletePay = async () => {
    try {
      setLoading(true);
      const data = {
        id: selected.map((item) => item.id),
        pago: 2,
        fechaPago: new Date().toISOString().split("T")[0],
      };
      await updatePaymentDomain(data);
      getDomainsHandler();
    } catch (error) {
      setLoading(false);
    }
  };

  const companyHandler = (id) => {
    if (user.companiesOptions) {
      const [company] = user.companiesOptions.filter((item) => item.value === id);

      return company.label;
    }
  };

  const totalImportHandler = (importe) => {
    let str = importe.toFixed(2).toString().split(".");
    //str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");  //añade un punto cada 3 cifras
    return str.join(",");
  };

  const selectHandler = (id, importe) => {
    let selection = selected;
    let idArray = [];
    let totalSum = 0;

    selection.forEach((item) => {
      idArray.push(item.id);
    });
    if (!idArray.includes(id)) {
      selection.push({ id, importe });
    } else {
      let removeIndex = selection.map((item) => item.id).indexOf(id);
      selection.splice(removeIndex, 1);
    }
    selection.forEach((item) => (totalSum += item.importe));
    setSum(totalSum.toFixed(2));
    setSelected(selection);
    setSelectedTotal(selection.length);
  };

  const filterHandler = (data) => {
    if (actualPage > 1){
      setOffset(0)
      setActualPage(1)
      searchParams.delete("offset")
      searchParams.delete("page")
    };
    if (data.type === "society" && data.item.length !== 0) {
      setDomains(null);
      setSocietyFilter(data.item.map((item) => item.value));
    } else if (data.type === "society" && data.item.length === 0) {
      setDomains(null);
      setSocietyFilter(undefined);
    }
  };

  return (
    <>
      <Row>
        <Col className="col-12">
          <div className={`clearfix mt-3 mb-3 containerTitle`}>
            <span className="tittle ml-4">Confirmar firma</span>
          </div>
        </Col>
      </Row>
      <Row className="mb-5 custom__relative">
        <h6>Filtros:</h6>

        <Col xl={2}>
          <Select
            placeholder="Seleccione sociedad"
            onChange={(item) => filterHandler({ item, type: "society" })}
            options={user.companiesOptions}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
      </Row>

      <Row>
        <Row>
          <h6>Tareas seleccionadas: {selectedTotal}</h6>
        </Row>
        <Col xl={10}>Importe Total: {sum} €</Col>
        <Col xl={2}>
          <Row>
            <Col>
              <h6 className="mt-2">Resultados: {domainsCount}</h6>
            </Col>

            <Col>
              <div class="container">
                {!loading ? (
                  <Button
                    disabled={selected.length !== 0 ? false : true}
                    className="btn_custom mb-2"
                    onClick={handleCompletePay}
                  >
                    Confirmar
                  </Button>
                ) : (
                  <Button className="btn_custom" disabled>
                    <Spinner
                      variant="warning"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Container fluid>
        <Row className="custom__header">
          <Col>
            <h5 className="input__id mt-2">Identificador</h5>
          </Col>
          <Col>
            <h5 className="input__id mt-2">Fecha Límite</h5>
          </Col>
          <Col>
            <h5 className="input__society mt-2">Sociedad</h5>
          </Col>
          <Col>
            <h5 className="input__importe mt-2">Importe</h5>
          </Col>
          <Col>
            <h5 className="input__iban mt-2">IBAN</h5>
          </Col>
          <Col>
            <h5 className="input__titular mt-2">Financiera</h5>
          </Col>

          <Col>
            <h5 className="input__concepto mt-2">Concepto</h5>
          </Col>

          <Col>
            <h5 className="input__checkbox mt-2">Pagar</h5>
          </Col>
        </Row>
      </Container>

      {domains ? (
        domains.length > 0 ? (
          domains.map((item) => (
            <Container fluid>
              <Row>
                <hr className="custom__line" />
              </Row>
              <Row className="my-2">
                <Col>
                  <h5
                    className="cursor_pointer"
                    onClick={() => navigate(`/gestion/${item.taskId}`)}
                  >
                    {item.taskId}
                  </h5>
                </Col>

                <Col>
                  <h5
                    className="cursor_pointer"
                    onClick={() => navigate(`/gestion/${item.taskId}`)}
                  >
                    {item.fechaLimite}
                  </h5>
                </Col>
                <Col>
                  <h5
                    className="cursor_pointer"
                    onClick={() => navigate(`/gestion/${item.taskId}`)}
                  >
                    {companyHandler(item.society)}
                  </h5>
                </Col>
                <Col>
                  <input
                    className="input__importe"
                    defaultValue={totalImportHandler(item.importe)}
                    readOnly
                  />
                </Col>
                <Col>
                  <input
                    className="input__iban"
                    defaultValue={item.iban}
                    readOnly
                  />
                </Col>
                <Col>
                  <input
                    className="input__titular"
                    defaultValue={item.financiera}
                    readOnly
                  />
                </Col>

                <Col>
                  <input
                    className="input__concepto"
                    defaultValue={item.concepto}
                    readOnly
                  />
                </Col>

                <Col>
                  <Form.Check
                    className="input__checkbox"
                    onClick={() => selectHandler(item.id, item.importe)}
                  />
                </Col>
              </Row>
            </Container>
          ))
        ) : (
          <div className="center">
            <h4 className="custom__space">
              No existen reservas de dominio pendientes de pago
            </h4>
          </div>
        )
      ) : (
        <div className="center">
          <h4 className="custom__space">
            <Spinner animation="border" variant="warning" />
          </h4>
        </div>
      )}
      {domainsCount && (
        <Pagination
          actualPage={actualPage}
          tasksCount={domainsCount}
          limit={limit}
          setActualPage={setActualPage}
          setSearchParams={setSearchParams}
          setOffset={setOffset}
        />
      )}
    </>
  );
};

export default Domains;
