
import { Button, Row, Col } from "react-bootstrap";
import { FaGoogle } from "react-icons/fa";

import "./GoogleLogin.css";
import { useUserContext } from "contexts/UserContext";

const GoogleLogin = () => {
  const { logInSocial, error } = useUserContext();

  return (
    <div>
      <Row>
        <Col xs={8} md={6} lg={4} xl={3} className="centered p-5 border">
          <Row>
            <h4>Inicio de sesión con Google</h4>
          </Row>
					{error && (
						<Row>
							<Col>
								<div className="alert alert-danger" role="alert">
									<p>{error}</p>
								</div>
							</Col>
						</Row>
					
					)}
          <Row>
            <Row>
              <Col>
                <Button
                  className="btn-flexicar d-block my-3"
                  type="submit"
                  variant="warning"
									onClick={logInSocial}
                >
                  <FaGoogle
                    className="mr-2 mt-0"
                    style={{ verticalAlign: "top" }}
                    size={22}
                  />
                  <span className="ml-2 font-weight-bold">Acceder</span>
                </Button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default GoogleLogin;
