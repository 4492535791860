import { config } from "../config/environment.config";
import { getAccessToken } from "../store/AccessTokenStore";




export const deleteFiles = async (taskId,fileId,fileType) => {
    try {
      return fetch(
        `${config.API_HOST}/task/${taskId}/files/${fileId}?fileType=${fileType}`, 
        {   
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
        }
      ).then((resp)=>resp);
    } catch (error) {
      throw error
    }
  };