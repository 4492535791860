import React, { useEffect, useState } from "react";

const RenderPdf = ({ data }) => {
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!data) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(data);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [data]);

  return (
    <div>
      {data && (
        <iframe
          title="pdf"
          src={preview}
          width="100%"
          height="400rem"
          allow="autoplay"
        ></iframe>
      )}
    </div>
  );
};

export default RenderPdf;