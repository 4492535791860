import { useEffect, useState, useCallback, useMemo } from "react";
import { Col, Form, Row, Spinner, Button } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";

import Pagination from "../../components/Pagination/Pagination";
import ModalDataPay from "../../components/ModalDataPay/ModalDataPay";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import TableCollapsible from "./Components/TableCollapsible/TableCollapsible";
import "./Historic.css";
import ModalDomain from "../../components/ModalDomain/ModalDomain";
import {
  getManagementTypes,
  getProcedureTypes,
  getTasks,
  getTaxesTypes,
  getVehicleOrigin,
} from "../../services/axiosCall";
import ModalDocumentation from "../../components/ModalDocumentation/ModalDocumentation";
import DeleteDomainModal from "../../components/DeleteModal/DeleteDomainModal";
import RolType from "../../constants/roles";
import useDebounce from "../../hooks/useDebounce";
import { useUserContext } from "contexts/UserContext";


const Historic = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const { user, socket } = useUserContext();

  const navigate = useNavigate();

  const [tasks, setTasks] = useState(null);
  const [total, setTotal] = useState(null);
  const [actualPage, setActualPage] = useState(parseFloat(query.get("page")) || 1);
  const [limit] = useState(25);
  const [tasksCount, setTasksCount] = useState(null);
  const [existingTask, setExistingTask] = useState(null);
  const [existingDomain, setExistingDomain] = useState(null);
  const [showDomain, setShowDomain] = useState(false);
  const [showEditDocumentation, setShowEditDocumentation] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteDomain, setShowDeleteDomain] = useState(false);
  const [gestionFilter, setGestionFilter] = useState(null);
  const [localFilter, setLocalFilter] = useState(query.get("local") || "");
  const [societyFilter, setSocietyFilter] = useState(null);
  const [tramiteFilter, setTramiteFilter] = useState(null);
  const [estadoFilter, setEstadoFilter] = useState(null);
  const [pagoFilter, setPagoFilter] = useState(null);
  const [domainFilter, setDomainFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState(query.get("search") || "");
  const [vehicleOrigin, setVehicleOrigin] = useState([]);
  const [taxesTypes, setTaxesTypes] = useState([]);
  const [procedureTypes, setProcedureTypes] = useState([]);
  const [managementTypes, setManagementTypes] = useState([]);
  
  const initialOffset = parseFloat(searchParams.get("offset")) || 0
  const [offset, setOffset] = useState(initialOffset);  


  const handleShowDomain = () => setShowDomain(!showDomain);
  const handleShowEditDocumentation = () =>
    setShowEditDocumentation(!showEditDocumentation);

  const handleClosePay = () => setShowPay(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleCloseDeleteDomain = () => setShowDeleteDomain(false);

  const debouncedSearchFilter = useDebounce(searchFilter, 500);

  const debouncedQuery = useDebounce(query, 500);

  const getTasksHandler = useCallback(async () => {
    if (user.dealerships.length > 0) {
      const response = await getTasks(
        {
          limit: limit,
          completed:true,
          offset: offset,
          locals: JSON.parse(debouncedQuery.get("local"))?.map(((local) => local?.value)),
          society: JSON.parse(debouncedQuery.get("society"))?.map(((society) => society?.value)),
          gestion: JSON.parse(debouncedQuery.get("gestion"))?.map(((gestion) => gestion?.value)),
          tramite: JSON.parse(debouncedQuery.get("tramite"))?.map(((local) => local?.value)),
          state: JSON.parse(debouncedQuery.get("status"))?.map(((status) => status?.value)),
          pay: 3,
          domain: JSON.parse(debouncedQuery.get("domain"))?.map(((domain) => domain?.value)),
          search: debouncedSearchFilter,
          userId: user.id,
        },
      );
       
      setTasks(response.data);
      setTasksCount(response.count);
    }
  }, [user.dealerships, limit, offset, debouncedQuery, debouncedSearchFilter, user.id]);
  

  useEffect(() => {
    if (socket) {
      socket.on("update", getTasksHandler);
      return () => {
        socket.off("update");
      };
    }
  }, [socket, getTasksHandler]);

  useEffect( () => {
    const handlerUseEffect = async () => {
     try {
       const vehicleOriginResponse = await getVehicleOrigin();
       setVehicleOrigin(vehicleOriginResponse);
 
       const taxesTypesResponse = await getTaxesTypes();
       setTaxesTypes(taxesTypesResponse);
 
       const procedureTypesResponse = await getProcedureTypes();
       setProcedureTypes(procedureTypesResponse);
 
       const managementTypesResponse = await getManagementTypes();
       setManagementTypes(managementTypesResponse);
 
     } catch (error) {
      //  navigate("/login");
     }
    
    }
    handlerUseEffect()
   }, [actualPage, navigate]);

  useEffect(() => {
    getTasksHandler();
  }, [getTasksHandler]);
  ;

  const filterHandler = (data) => {
    if (actualPage > 1){
      setOffset(0)
      setActualPage(1)
      searchParams.delete("offset")
      searchParams.delete("page")
    };
    const type = data.type;

    if (
      type === "search" &&
      (data.e.target.value.length > 0)
    ) {
      const search = data.e.target.value;
      setSearchFilter(search);
      searchParams.set("search", search);
    } else if (data.type === "search" && data.e.target.value.length === 0) {
      setSearchFilter(undefined);
      searchParams.has("search") && searchParams.delete("search");
    }
  
    if (type === "gestion" && data.item.length !== 0) {
      const gestion = data.item;
      searchParams.set("gestion", JSON.stringify(gestion));
      setGestionFilter(gestion);
    } else if (data.type === "gestion" && data.item.length === 0) {
      setGestionFilter(undefined);
      searchParams.delete("gestion");
    }

    if (type === "society" && data.item.length !== 0) {
      const society = data.item;
      setSocietyFilter(society);
      searchParams.set("society", JSON.stringify(society));
      
    } else if (data.type === "society" && data.item.length === 0) {
      setSocietyFilter(undefined);
      searchParams.delete("society");
    }

    if (type === "local" && data.item.length !== 0) {
      const localValue = data.item;
      setLocalFilter(localValue);
      
      searchParams.set("local", JSON.stringify(localValue));
    } else if (data.type === "local" && data.item.length === 0) {
      setLocalFilter(undefined);
      searchParams.delete("local");
    }

    if (type === "tramite" && data.item.length !== 0) {
      const tramite  = data.item;
      setTramiteFilter(tramite);
      searchParams.set("tramite", JSON.stringify(tramite));
    } else if (data.type === "tramite" && data.item.length === 0) {
      setTramiteFilter(undefined);
      searchParams.delete("tramite");
    }

    if (type === "estado" && data.item.length !== 0) {
      const status = data.item;
      setEstadoFilter(status);
      searchParams.set("status", JSON.stringify(status));
    } else if (data.type === "estado" && data.item.length === 0) {
      setEstadoFilter(undefined);
      searchParams.delete("status");
    }

    if (type === "pago" && data.item.length !== 0) {
      const payment = data.item;
      setPagoFilter(payment);
      searchParams.set("pay", JSON.stringify(payment));
    } else if (data.type === "pago" && data.item.length === 0) {
      setPagoFilter(undefined);
      searchParams.delete("pay");
    }

    if (type === "domain" && data.item.length !== 0) {
      const domain = data.item;
      setDomainFilter(domain);
      searchParams.set("domain", JSON.stringify(domain));
    } else if (data.type === "domain" && data.item.length === 0) {
      setDomainFilter(undefined);
      searchParams.delete("domain");
    }
     setSearchParams(searchParams);
  };

  const clearFilters = () => {
    setSearchParams(new URLSearchParams(""));
    setGestionFilter("");
    setTramiteFilter("");
    setLocalFilter("");
    setSocietyFilter("");
    setSearchFilter("");
    setOffset(0)
    setActualPage(1)
  }
 
  return (
    <>
      <Row>
        <Col className="col-12">
          <div className={`clearfix mt-3 mb-3 containerTitle`}>
            <span className="tittle ml-4">Gestiones</span>
          </div>
        </Col>
      </Row>
      <Row className="mb-5 custom__relative">
        <h6>Filtros:</h6>
        <Col>
          <Select
            placeholder="Seleccione tipo de gestión"
            onChange={(item) => filterHandler({ item, type: "gestion" })}
            options={managementTypes}
            value={JSON.parse(query.get("gestion"))}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        <Col>
          <Select
            placeholder="Seleccione tipo de trámite"
            onChange={(item) => filterHandler({ item, type: "tramite" })}
            options={procedureTypes}
            isMulti
            value={JSON.parse(query.get("tramite"))}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        <Col>
          <Select
            placeholder="Seleccione sociedad"
            onChange={(item) => filterHandler({ item, type: "society" })}
            options={user.companiesOptions}
            isMulti
            value={JSON.parse(query.get("society"))}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        {user.roles?.some(
          (role) =>
            role === RolType.CENTRAL_ADMIN || role === RolType.GENERAL_ADMIN
        ) && (
          <Col>
            <Select
              placeholder="Seleccione local"
              onChange={(item) => filterHandler({ item, type: "local" })}
              options={user.dealershipOptions}
              value={localFilter}
              isMulti
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#fe9800",
                  primary25: "#ffc548",
                },
              })}
            />
          </Col>
        )}
        <Col>
          <Form>
            <Form.Group>
              <Form.Control
                placeholder="Matrícula, DNI, CIF, NIE"
                onChange={(e) => filterHandler({ e, type: "search" })}
                value={searchFilter}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col xl={2}>
          {Object.keys(Object.fromEntries([...searchParams])).length > 0 && (
            <Button variant="secondary" onClick={() => clearFilters()}>
              Borrar filtros
            </Button>
          )}
        </Col>
      </Row>

      <Row>
        <Col xl={2}>
          <Row>
            <Col>
              <h6 className="mt-2">Total: {tasksCount}</h6>
            </Col>
          </Row>
        </Col>
      </Row>

      {tasks ? (
        tasks.length > 0 ? (
          <TableCollapsible
            tasks={tasks}
            setExistingTask={setExistingTask}
            handleShowDomain={handleShowDomain}
          />
        ) : (
          <div className="center">
            <h4 className="custom__space">No existen gestiones.</h4>
          </div>
        )
      ) : (
        <div className="center">
          <h4 className="custom__space">
            <Spinner animation="border" variant="warning" />
          </h4>
        </div>
      )}

      {tasks && tasks.length > 0 && (
      <Pagination
          actualPage={actualPage}
          tasksCount={tasksCount}
          limit={limit}
          setOffset={setOffset}
          setActualPage={setActualPage}
          setSearchParams={setSearchParams}
        />
      )}

      <ModalDomain
        show={showDomain}
        handleClose={handleShowDomain}
        existingDomain={existingTask?.domain}
        task={existingTask}
      />

      <ModalDocumentation
        show={showEditDocumentation}
        handleClose={handleShowEditDocumentation}
        existingTask={existingTask}
      />

      <ModalDataPay
        show={showPay}
        handleClose={handleClosePay}
        task={existingTask}
        total={total}
      />

      <DeleteModal
        show={showDelete}
        handleClose={handleCloseDelete}
        task={existingTask}
      ></DeleteModal>

      <DeleteDomainModal
        show={showDeleteDomain}
        handleClose={handleCloseDeleteDomain}
        domain={existingDomain}
      ></DeleteDomainModal>
    </>
  );
};

export default Historic;
