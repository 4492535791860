import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { UserContextProvider } from "contexts/UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { config } from "./config/environment.config";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
				<UserContextProvider>
					<App />
				</UserContextProvider>
			</GoogleOAuthProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
