import { Container, Navbar } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
//components
import AppRouter from "./components/AppRouter/AppRouter";
import Header from "./components/Navbar/components/Header";

import { useUserContext } from "contexts/UserContext";
import { useEffect } from "react";

function App() {
  const { user, socket } = useUserContext();

  useEffect(() => {
    return () => {
      if (socket) {
        socket.off();
        socket.disconnect();
      }
    };
  }, [socket]);

  return (
    <div className="App">
      <ToastContainer />
      <Container fluid>
        {user ? (
          <Header />
        ) : (
          <Navbar bg="light">
            <Navbar.Brand href="/">
              <img
                alt=""
                src="./logo-flexicar.svg"
                width="200"
                className="d-inline-block align-top"
              />{" "}
            </Navbar.Brand>
          </Navbar>
        )}
        <main>
          <AppRouter />
        </main>
      </Container>
    </div>
  );
}

export default App;
