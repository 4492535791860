const PAYMENT_OPTIONS = [
  { label: "Sin tramitar", value: 1 },
  { label: "Revisado", value: 4 },
  { label: "Firma Pendiente", value: 2 },
  { label: "Completado", value: 3 },
];

const ORIGIN_OPTIONS = [
  { label: "Particular", value: 1 },
  { label: "Autónomo", value: 2 },
  { label: "Empresa Colaboradora", value: 3 },
  { label: "Empresa No Colaboradora", value: 4 },
];

const STATE_OPTIONS = [
  { label: "Pendiente", value: 1 },
  { label: "Listo", value: 2 },
  { label: "Listo GV", value: 3 },
  { label: "Carpeta", value: 4 },
  { label: "Enviado", value: 5 },
  { label: "Proforma", value: 6 },
  { label: "Notificado", value: 7 },
  { label: "Transferido", value: 8 },
  { label: "Facturado", value: 9 },
  { label: "Con cargas", value: 10 },
  { label: "Entregado", value: 11 },
  { label: "Rescindido", value: 12 },
  { label: "Transf./notif", value: 13 },
];

const INCIDENCE_OPTIONS = [
  { label: "Pendiente de resolución", value: 0 },
  { label: "Revisión solicitada", value: 1 },
];

const DOMAIN_OPTIONS = [
  { label: "Sin validar", value: 1 },
  { label: "Revisado", value: 4 },
  { label: "Firma Pendiente", value: 2 },
  { label: "Completado", value: 3 },
];

export { DOMAIN_OPTIONS, INCIDENCE_OPTIONS, PAYMENT_OPTIONS, STATE_OPTIONS, ORIGIN_OPTIONS };
