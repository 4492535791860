import ProviderFrom from "./ProviderForm";
import InvoiceForm from "./InvoiceForm";
import { useState } from "react";
import ModalDocumentation from "../../components/ModalDocumentation/ModalDocumentation";
import ModalNewProvider from "../../components/ModalNewProvider/ModalNewProvider";
import { createInvoice, uploadInvoiceFile } from "../../services/invoices";
import { getApiError } from "./constants";
import {
  errorToast,
  loadToast,
  updateToast,
} from "../../helpers/toastFunction";

const INVOICE_FILE_API_TYPE = "GENERAL_INVOICE";

const AddInvoice = () => {
  const [providerInfo, setProviderInfo] = useState(null);
  const [showEditDocumentation, setShowEditDocumentation] = useState(false);
  const [showNewProvider, setShowNewProvider] = useState(false);
  const [fileState, setFileState] = useState(null);
  const [newProvider, setNewProvider] = useState(undefined);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleShowEditDocumentation = () => {
    setShowEditDocumentation(!showEditDocumentation);
  };

  const postInvoiceFile = async () => {
    try {
      let invoiceFileForm = new FormData();
      invoiceFileForm.append("type", INVOICE_FILE_API_TYPE);
      invoiceFileForm.append("file", fileState);
      const response = await uploadInvoiceFile(invoiceFileForm);
      return response.id;
    } catch (error) {
      const errorCode = error?.response?.data?.code || error.code;
      const apiErrorMessages = getApiError(errorCode);
      errorToast(apiErrorMessages);
    }
  };

  const postInvoice = async (data, fileId) => {
    const loadToastId = loadToast("Subiendo nueva factura...", {
      isLoading: true,
    });
    try {
      await createInvoice({
        ...data,
        fileId,
      });
      updateToast(loadToastId, "Se ha subido correctamente la factura", {
        type: "success",
        isLoading: false,
      });
    } catch (error) {
      const errorCode = error?.response?.data?.code || error.code;
      const apiErrorMessages = getApiError(errorCode);
      updateToast(loadToastId, apiErrorMessages, {
        type: "error",
        isLoading: false,
      });
    }
  };

  const invoiceSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const fileId = await postInvoiceFile();

      if (fileId && providerInfo) {
        await postInvoice(
          {
            ...data,
            providerId: providerInfo.id,
          },
          fileId
        );
      }
    } catch (error) {
      const errorCode = error?.response?.data?.code || error.code;
      const apiErrorMessages = getApiError(errorCode);
      errorToast(apiErrorMessages);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <ProviderFrom
        providerInfo={providerInfo}
        setProviderInfo={setProviderInfo}
        newProvider={newProvider}
        setShowNewProvider={setShowNewProvider}
      />
      <InvoiceForm
        providerInfo={providerInfo}
        handleShowEditDocumentation={handleShowEditDocumentation}
        invoiceSubmit={invoiceSubmit}
        fileState={fileState}
        newProvider={newProvider}
        isSubmitting={isSubmitting}
      />

      {showEditDocumentation && (
        <ModalDocumentation
          show={showEditDocumentation}
          handleClose={handleShowEditDocumentation}
          existingTask={{}}
          type={"Factura general"}
          setFileState={setFileState}
        />
      )}
      {showNewProvider && (
        <ModalNewProvider
          show={showNewProvider}
          handleClose={() => setShowNewProvider(false)}
          handlerGetProviders={undefined}
          setNewProvider={setNewProvider}
        />
      )}
    </div>
  );
};

export default AddInvoice;
