import React, {  useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { createIncidence } from "../../services/incidence.service";

import "./ModalIncidence.css";

const ModalIncidence = ({ show, handleClose, task }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset({
      tittle: null,
      description: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = async (data) => {
    createIncidence({ ...data, taskId: task.id });
    handleClose();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="modal_header">
            <Modal.Title>Añadir Incidencia</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <h6>Título:</h6>
                <Col className="my-1">
                  <input
                    className={`form-control ${errors.tittle && "is-invalid"} `}
                    placeholder="Título"
                    {...register("tittle", { required: true })}
                  />
                  {errors.tittle?.type === "required" && (
                    <span className="error">
                      Introduza el título de la incidencia
                    </span>
                  )}
                </Col>
              </Row>

              <Row>
                <label>Descripción:</label>
                <Col className="my-1">
                  <textarea
                    type="text"
                    minlength="10"
                    maxlength="500"
                    className={`form-control ${
                      errors.description && "is-invalid"
                    } `}
                    placeholder="Descripción de la incidencia"
                    {...register("description", { required: true })}
                  />
                  {errors.description?.type === "required" && (
                    <span className="error">
                      Introduza la descripción de la incidencia
                    </span>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn_custom" onClick={handleSubmit(onSubmit)}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
};

export default ModalIncidence;
