import { INVOICE_FIELDS } from "./constants";

const errorMessages = {
  [INVOICE_FIELDS.identification.name]: {
    required: "Introduzca un CIF, DNI o NIE",
    validate: "Introduzca un CIF, DNI o NIE válido",
  },
  [INVOICE_FIELDS.date.name]: {
    required: "Introduzca una fecha",
  },
  [INVOICE_FIELDS.invoiceId.name]: {
    required: "Introduzca el número de factura",
  },
  [INVOICE_FIELDS.category.name]: {
    required: "Seleccione una categoría",
  },
  [INVOICE_FIELDS.totalAmount.name]: {
    required: "Introduzca el importe de total a pagar",
    validate: "El importe total a pagar no el correcto",
  },
  [INVOICE_FIELDS.local.name]: {
    required: "Seleccione un local",
  },
  [INVOICE_FIELDS.society.name]: {
    required: "Seleccione una sociedad",
  },
  [INVOICE_FIELDS.payment.name]: {
    required: "Seleccione un método de pago",
  },
  [INVOICE_FIELDS.irpf.name]: {
    required: "Seleccione el porcentaje de IRPF",
  },
  [INVOICE_FIELDS.ivaResult.name]: {
    validate: "El resultado de base e iva no es el correcto",
  },
  [INVOICE_FIELDS.iva.name]: {
    required: "Introduzca el iva de la factura",
  },
  [INVOICE_FIELDS.base.name]: {
    required: "Introduzca la base de la factura",
    validate: "La base tiene que ser mayor que cero",
  },
  [INVOICE_FIELDS.invoiceFile.name]: {
    required: "Es necesario añadir un fichero",
    validate: "Añada un fichero pdf",
  },
};

const Errors = ({
  errors,
  field,
  file,
  fieldArrayIndex,
  fieldArrayName,
  isFieldArray = false,
}) => {
  const hasErrors = errors && Object.keys(errors).length > 0;

  if (errors[field] && hasErrors) {
    if (isFieldArray) {
      const { type } = errors[field][fieldArrayIndex][fieldArrayName];
      return (
        <span className="error">{errorMessages[fieldArrayName][type]}</span>
      );
    }
    const { type } = errors[field];
    return type ? (
      <span className="error">{errorMessages[field][type]}</span>
    ) : (
      <></>
    );
  } else if (field === INVOICE_FIELDS.invoiceFile.name) {
    if (file) {
      if (file?.[0]?.type && file?.[0]?.type !== "application/pdf") {
        return <span className="error">{errorMessages[field].validate}</span>;
      } else {
        return <></>;
      }
    } else {
      return <span className="error">{errorMessages[field].required}</span>;
    }
  } else {
    return <></>;
  }
};

export default Errors;
