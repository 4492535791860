import { config } from "../config/environment.config";
import { axiosInstance } from "./BaseService";

export const getInvoices = async (data) => {
    return axiosInstance.get(`${config.API_HOST}/invoice`, {
      params: data,
    });
};

export const createInvoice = async (formData) => {
    return axiosInstance.post(`${config.API_HOST}/invoice`, formData);
};

export const getInvoice = async (id) => {
    return axiosInstance.get(`${config.API_HOST}/invoice/${id}`);
};

export const updateInvoice = async (id, data) => {
    return axiosInstance.patch(`${config.API_HOST}/invoice/${id}`, data);
};

export const uploadInvoiceFile = async (fromData) => {
    return axiosInstance.post(`${config.API_HOST}/storage`, fromData);
}
