import { removeTokens } from "../../../store/AccessTokenStore";
import RolType from "../../../constants/roles";

export const menuItems = [
  {
    title: "Facturas Vehículos",
    url: "#",
    rol: [RolType.GENERAL_ADMIN, RolType.CENTRAL_ADMIN],
    submenu: [
      {
        title: "Histórico",
        url: "/historial",
      },
      {
        title: "Pagos",
        submenu: [
          {
            title: "Pendientes",
            url: "/gestiones/pagar",
          },
          {
            title: "Confirmar Firma",
            url: "/gestiones/confirmar",
          },
        ],
      },
      {
        title: "Reservas Dominio",
        submenu: [
          {
            title: "Pendientes",
            url: "/reserva_dominio/pendientes",
          },
          {
            title: "Confirmar Firma",
            url: "/reserva_dominio/confirmar",
          },
        ],
      },
      {
        title: "Gestiones",
        url: "/home",
      },
      {
        title: "Añadir Gestión",
        url: "/nueva-gestion",
      },
    ],
  },
  {
    title: "Facturas Vehículos",
    url: "#",
    rol: Object.values(RolType).map((item) => {
      if (
        item !== RolType.GENERAL_ADMIN &&
        item !== RolType.CENTRAL_ADMIN &&
        item !== RolType.CENTRAL_APPRAISER
      ) {
        return item;
      }
      return null;
    }),
    submenu: [
      {
        title: "Histórico",
        url: "/historial",
      },
      {
        title: "Gestiones",
        url: "/home",
      },
      {
        title: "Añadir Gestión",
        url: "/nueva-gestion",
      },
    ],
  },
  {
    title: "Facturas Vehículos",
    url: "#",
    rol: [RolType.CENTRAL_APPRAISER],
    submenu: [
      {
        title: "Histórico",
        url: "/historial",
      },
      {
        title: "Gestiones",
        url: "/home",
      },
    ],
  },
  {
    title: "",
    url: "#",
    rol: Object.values(RolType).map((item) => item),
    submenu: [
      {
        title: "Mi Perfil",
        url: "/perfil",
      },
      {
        title: "Cerrar Sesión",
        url: "/login",
        action: () => {
          removeTokens();
        },
      },
    ],
  },
];

const getMenu = (userRole) => {
  const menuFilter = menuItems.filter((item) => item.rol.includes(userRole));
  return menuFilter;
};

export default getMenu;
