import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Header = () => {
  return (
    <header>
      <div className="nav-area">
        <Link to="">
          <img alt="" src="./logo-flexicar.svg" width="200" className="logo" />
        </Link>
        <Navbar />
      </div>
    </header>
  );
};

export default Header;
