export const CATEGORY_OPTIONS = [
    { value: 1, label: "Agua Oficina" },
    { value: 2, label: "Alarma" },
    { value: 3, label: "Alimentación" },
    { value: 4, label: "Alojamiento" },
    { value: 5, label: "Alquiler de locales" },
    { value: 6, label: "Circulación y aparcamiento" },
    { value: 7, label: "Equipos informáticos" },
    { value: 8, label: "Gasolina" },
    { value: 9, label: "Impuestos" },
    { value: 10, label: "ITV" },
    { value: 11, label: "Lavados" },
    { value: 12, label: "Limpieza" },
    { value: 13, label: "Material de oficina" },
    { value: 14, label: "Mensajería" },
    { value: 15, label: "Publicidad" },
    { value: 16, label: "Alquiler de material" },
    { value: 17, label: "Reparación y conservación" },
    { value: 18, label: "Servicios profesionales independientes" },
    { value: 19, label: "Seguros" },
    { value: 20, label: "Software" },
    { value: 21, label: "Suministro Agua" },
    { value: 22, label: "Suministro Gas" },
    { value: 23, label: "Suministro Luz" },
    { value: 24, label: "Suministro Telefonía" },
    // { value: 25, label: "Taller garantía" },
    // { value: 26, label: "Taller preparación" },
    { value: 27, label: "Transporte de vehículos" },
    { value: 28, label: "Transporte de personal" },
  ];
  
  export const IVA_OPTIONS = [
    { value: 21, label: "21%" },
    { value: 10, label: "10%" },
    { value: 5, label: "5%" },
    { value: 4, label: "4%" },
    { value: 0, label: "0%" },
  ];
  
  export const IRPF_OPTIONS = [
    { value: 19, label: "19%" },
    { value: 15, label: "15%" },
    { value: 7, label: "7%" },
    { value: 1, label: "1%" },
    { value: 0, label: "0%" },
  ];
  
  export const PAYMENT_METHODS = [
    // { value: 1, label: "Transferencia" },
    { value: 2, label: "Caja" },
    { value: 3, label: "Domiciliación" },
    { value: 4, label: "Tarjeta" },
    { value: 5, label: "Paypal" },
    { value: 6, label: "Transferencia Previa" },
    { value: 7, label: "Compensación" },
  ];