export const getAccessToken = () => window.localStorage.getItem("access_token");
export const getRefreshToken = () =>
  window.localStorage.getItem("refresh_token");

export const setTokens = (tokens) => {
  window.localStorage.setItem("access_token", tokens.access_token);
  window.localStorage.setItem("refresh_token", tokens.refresh_token);
};

export const removeTokens = () => {
  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("refresh_token");
};
