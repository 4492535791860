import React from "react";
import { Navigate } from "react-router-dom";

import { useUserContext } from "contexts/UserContext";

interface ProtectedRouteProps {
  component: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const { user, isLoading } = useUserContext();

  if (isLoading) return <p>...</p>;
  return user?.id ? Component : <Navigate to="/login" />;
};

export default ProtectedRoute;
