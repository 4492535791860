import { Col, Container, Row } from "react-bootstrap";

import profileIcon from "./profile-image.jpg";
import "./Profile.css";
import { useUserContext } from "contexts/UserContext";

const Profile = () => {
  const { user } = useUserContext();

  return (
    <Container>
      <Row>
        <Col className="col-12">
          <div className={`clearfix mt-3 mb-3 containerTitle`}>
            <span className="tittle ml-4">Mi perfil</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
          <img className="photo" src={profileIcon} alt="Logo" />
        </Col>
        <Col className="mt-5">
          <h5> Email: {user.email}</h5>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
