import { plateRegex } from "../utils/constants/regex";


const validationPlate = (plate, imported) => {
  if(imported){
    return true
  }
  else {
    if(plate.length > 7) {
      return false
    }
    return plateRegex.test(plate.toUpperCase())
  }
};

export default validationPlate;