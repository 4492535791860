import React, { useState, useEffect } from "react";
import { Table, Col, Row, Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useSearchParams } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./ProviderList.css";
import Select from "react-select";
import { getProviders } from "../../services/providers";
import { updateProvider } from "../../services/providers";
import isIBAN from "../../utils/constants/iban";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { errorToast } from "../../helpers/toastFunction";
import ModalNewProvider from "../../components/ModalNewProvider/ModalNewProvider";
import useDebounce from "../../hooks/useDebounce";

function ProviderList() {
  const [nameFilter, setNameFilter] = useState(undefined);
  const [cpFilter, setCpFilter] = useState(undefined);
  const [ibanFilter, setIbanFilter] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tasksCount, setTasksCount] = useState(null);
  const [showDomain, setShowDomain] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [limit] = useState(10);
  const [filterChanged, setFilterChanged] = useState(false);
  const [providers, setProviders] = useState([]);
  const [identificationFilter, setIdentificationFilter] = useState(undefined);
  const [totalPages, setTotalPages] = useState(null);
  const [saveNewProvider, setSaveNewProvider] = useState(false);

  const debouncedSearchTerm = useDebounce(nameFilter, 500);
  const debouncedSearchIban = useDebounce(ibanFilter, 500);
  const debouncedSearchIdentification = useDebounce(identificationFilter, 500);

  const handlerGetProviders = React.useCallback(async () => {
    try {
      const response = await getProviders({
        order: "ASC",
        page: actualPage,
        take: limit,
        identification: debouncedSearchIdentification,
        name: debouncedSearchTerm,
      });
      setTotalPages(response.meta.pageCount);
      setProviders(response.data);
      setTasksCount(response.data.length);
    } catch (error) {
      return errorToast("No se ha podido cargar la lista de proveedores ");
    }
  }, [actualPage, limit, debouncedSearchIdentification, debouncedSearchTerm]);

  useEffect(() => {
    handlerGetProviders();
  }, [handlerGetProviders, saveNewProvider]);

  const pageHandler = (action) => {
    if (action === "next") {
      if (actualPage < totalPages) {
        setActualPage(actualPage + 1);
      }
    } else {
      if (actualPage > 1) {
        setActualPage(actualPage - 1);
      }
    }
  };

  const filterHandler = (data) => {
    const value = data.e.target.value;
    const type = data.type;

    if (type === "name" && value.length > 0) {
      searchParams.set("name", value);
      setNameFilter(value);
    } else if (type === "name" && value.length === 0) {
      setNameFilter(undefined);
      searchParams.has("name") && searchParams.delete("name");
    }
    if (type === "search" && value.length > 0) {
      searchParams.set("search", value);
      setIdentificationFilter(value);
    } else if (type === "search" && value.length === 0) {
      setIdentificationFilter(undefined);
      searchParams.has("search") && searchParams.delete("search");
    }
    if (type === "iban" && value.length > 0) {
      if (isIBAN(value)) {
        setIbanFilter(value);
        searchParams.set("iban", value);
      }
      searchParams.set("iban", value);
      setIbanFilter(value);
    } else if (type === "iban" && value.length === 0) {
      setIbanFilter(undefined);
      searchParams.has("iban") && searchParams.delete("iban");
    }
    setSearchParams(searchParams);
  };

  const handleShowDomain = () => setShowDomain(!showDomain);

  const clearFilter = () => {
    setIdentificationFilter("");
    setSearchParams("");
    setCpFilter("");
    setIbanFilter("");
    setNameFilter("");
  };
  const handleCheckImpound = async (id, blocked) => {
    try {
      const response = await updateProvider(id, {
        blockedAt: blocked ? null : new Date(),
      });
      if (response) {
        handlerGetProviders();
      }
    } catch (error) {
      return errorToast("No se ha podido cargar la lista de proveedores ");
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="mt-3">
          <Col className="col-12">
            <div className={`clearfix mt-3 mb- containerTitle`}>
              <span className="tittle ml-4">Proveedores</span>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xl={2}>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  value={nameFilter}
                  onChange={(e) => filterHandler({ e, type: "name" })}
                  placeholder="Nombre del Proveedor"
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xl={2}>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  value={identificationFilter}
                  onChange={(e) => filterHandler({ e, type: "search" })}
                  placeholder="DNI, CIF, NIE"
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xl={2}>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control
                  value={ibanFilter}
                  onChange={(e) => filterHandler({ e, type: "iban" })}
                  placeholder="Número de Cuenta"
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xl={0}>
            <Button className="btn_custom" onClick={() => handleShowDomain()}>
              Crear Proveedor
            </Button>
          </Col>
          <Col xl={2}>
            {Object.keys(Object.fromEntries([...searchParams])).length > 0 && (
              <Button variant="secondary" onClick={() => clearFilter()}>
                Borrar filtros
              </Button>
            )}
          </Col>
        </Row>

        <Row className="mt-5">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th className="date_custom_width">Fecha Alta</th>
                <th style={{ width: "15%" }}>Nombre</th>
                <th style={{ width: "10%" }}>CIF, DNI, NIE</th>
                <th style={{ width: "5%" }}>C.P.</th>
                <th style={{ width: "35%" }}>Dirección</th>
                <th style={{ width: "20%" }}>Nº cuenta</th>
                <th style={{ width: "10%" }}>Embargo</th>
              </tr>
            </thead>
            <tbody>
              {providers.map((provider) => (
                <tr
                  key={provider.id}
                  data-toggle="collapse"
                  data-target=".multi-collapse1"
                  aria-controls="multiCollapseExample1"
                  className="clickable cursor_pointer"
                >
                  <td>{new Date(provider.createdAt).toLocaleDateString()}</td>
                  <td>{provider.name}</td>
                  <td>{provider.identification}</td>
                  <td>{provider.zipCode}</td>
                  <td>{provider.direction}</td>
                  <td styles={{ width: "70%" }}>
                    <Select
                      placeholder={provider?.accounts?.[0]?.iban || "Nº cuenta"}
                      ignoreAccents={true}
                      ignoreCase={true}
                      matchFrom="any"
                      trim={true}
                      options={provider?.accounts?.map((account) => ({
                        value: account.id,
                        label: account.iban,
                      }))}
                    />
                  </td>
                  <td>
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-${provider.id}`}>
                          <strong>
                            {provider.blockedAt
                              ? new Date(
                                  provider.blockedAt
                                ).toLocaleDateString()
                              : "Sin Embargo"}
                          </strong>
                          .
                        </Tooltip>
                      }
                    >
                      <div class="form-check form-switch d-flex justify-content-center">
                        <label
                          class="form-check-label"
                          for={`checkbox-${provider.id}`}
                        ></label>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          defaultChecked={provider.blockedAt}
                          onChange={() =>
                            handleCheckImpound(provider.id, provider.blockedAt)
                          }
                          id={provider.id}
                          name={`checkbox-${provider.id}`}
                        />
                      </div>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </Container>
      {providers && providers.length > 0 && (
        <nav>
          <div className="pagination__container">
            <div
              onClick={() => pageHandler("back")}
              className="pagination__element"
            >
              {actualPage !== 1 && (
                <AiOutlineArrowLeft
                  title="Anterior"
                  size={20}
                  color={"#E88A00"}
                />
              )}
            </div>
            <div className="pagination__element">
              <p className="pagination__text">
                {actualPage}/{totalPages}
              </p>
            </div>
            <div
              onClick={() => pageHandler("next")}
              className="pagination__element"
            >
              {actualPage !== totalPages && (
                <AiOutlineArrowRight
                  title="Siguiente"
                  size={20}
                  color={"#E88A00"}
                />
              )}
            </div>
          </div>
        </nav>
      )}
      {showDomain ? (
        <ModalNewProvider
          show={showDomain}
          handleClose={handleShowDomain}
          handlerGetProviders={handlerGetProviders}
        />
      ) : null}
    </>
  );
}

export default ProviderList;
