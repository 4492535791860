import React from "react";
import { Button, Modal } from "react-bootstrap";
import Spreadsheet from "react-spreadsheet";
import "./ModalDataPay.css";

const ModalDataPay = ({ task, total, handleClose, show }) => {
  const data = [
    [
      { value: "Cliente" },
      { value: "Concepto" },
      { value: "iban" },
      { value: "importe" },
    ],
    [
      { value: task?.titular },
      { value: task?.concepto },
      { value: task?.iban },
      { value: total },
    ],
  ];

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="modal_header">
          <Modal.Title>Datos transferencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Spreadsheet data={data} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalDataPay;
