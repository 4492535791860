import { toast } from "react-toastify";

const DEFAULT_OPTION = {
  position: "bottom-right",
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  isLoading: true, // true, false
  type: 'info' // 'info', 'success', 'warning', 'error', 'default'
};

export const warningToast = (text) =>
  toast.warn(text, {
    position: "bottom-right",
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const errorToast = (text) =>
  toast.error(text, {
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const successToast = (text) =>
  toast.success(text, {
    position: "bottom-right",
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const loadToast = (text, option) =>
  toast.loading(text, {
    ...DEFAULT_OPTION,
    ...option,
  });

export const updateToast = (toastId, text, option) =>
  toast.update(toastId, {
    render: text,
    ...DEFAULT_OPTION,
    ...option,
  });

  export const dismissToast = () => toast.dismiss();
