import { useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import { deleteDomain } from "../../services/domain.service";
import "./index.module.css";

const DeleteDomainModal = (props) => {
  const { show, handleClose, domain, taskId } = props;
  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    setLoading(true);
    try {
      const result = await deleteDomain(domain.id, taskId);
      if (result) {
        handleClose();
        setLoading(false);
      }
    } catch (error) {}
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header onHide={handleClose} className="modal_header">
          <h5>{`Eliminar reserva de dominio`}</h5>
        </Modal.Header>
        <Modal.Body>
          Pulse eliminar para confirmar el borrado de la reserva de dominio.
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-center">
              {!loading ? (
                <Button className="btn_custom" onClick={deleteHandler}>
                  Eliminar
                </Button>
              ) : (
                <Button className="btn_custom" disabled>
                  Eliminando...
                  <Spinner
                    variant="warning"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              )}
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteDomainModal;
