export const BUTTON_STATES = {
  CHECK_PROVIDER: "Comprobar Proveedor",
  ADD_PROVIDER: "Añadir Proveedor",
  CHECKING: "...",
};

export const INVOICE_FIELDS = {
  identification: {
    name: "identification",
    label: "CIF, DNI o NIE",
  },
  date: {
    name: "date",
    label: "Fecha",
  },
  invoiceId: {
    name: "invoiceNumber",
    label: "Nº Factura",
  },
  category: {
    name: "type",
    label: "Categoría",
  },
  base: {
    name: "base",
    label: "base",
  },
  iva: {
    name: "iva",
    label: "IVA",
  },
  ivaResult: {
    name: "amount",
    label: "Resultados",
  },
  irpf: {
    name: "irpf",
    label: "IRPF",
  },
  totalAmount: {
    name: "totalAmount",
    label: "Total A Pagar",
  },
  local: {
    name: "localId",
    label: "Tiendas",
  },
  society: {
    name: "societyId",
    label: "Sociedades",
  },
  payment: {
    name: "payment",
    label: "Método de Pago",
  },
  invoicePdf: {
    name: "invoicePdf",
    label: "PDF Factura",
  },
  comments: {
    name: "comments",
    label: "Observaciones",
  },
  invoiceFile: {
    name: "invoiceFile",
    label: "PDF Factura",
  },
  baseIvaFields: {
    name: "detail",
    label: "Base + IVA + Resultado",
    base: {
      name: "base",
      label: "Base",
    },
    iva: {
      name: "iva",
      label: "IVA",
    },
    ivaResult: {
      name: "amount",
      label: "Resultados",
    },
  },
};

export const API_ERRORS_CODE = [
  "account.exists.iban",
  "account.iban.error",
  "invoice.exists.active",
  "invoice.exists.error",
  "provider.not.exist",
  "provider.error.account",
  "payment.method.error",
  "invoice.type.error",
  "dealer.not.exist",
  "ERR_BAD_REQUEST",
];

export const API_ERRORS = {
  ACCOUNT_ALREADY_EXISTS: API_ERRORS_CODE[0],
  ACCOUNT_NOT_VALID: API_ERRORS_CODE[1],
  INVOICE_ALREADY_EXISTS: API_ERRORS_CODE[2],
  INVOICE_RELATED_NOT_FOUND: API_ERRORS_CODE[3],
  PROVIDER_NOT_FOUND: API_ERRORS_CODE[4],
  PROVIDER_ERROR_ACCOUNT: API_ERRORS_CODE[5],
  PAYMENT_METHOD_NOT_FOUND: API_ERRORS_CODE[6],
  INVOICE_TYPE_NOT_FOUND: API_ERRORS_CODE[7],
  DEALER_NOT_FOUND: API_ERRORS_CODE[8],
  ERR_BAD_REQUEST: API_ERRORS_CODE[9],
};

export const API_ERRORS_MESSAGES = {
  ACCOUNT_ALREADY_EXISTS: "Ya existe una cuenta con estos datos",
  ACCOUNT_NOT_VALID: "La cuenta no es válida",
  INVOICE_ALREADY_EXISTS: "Ya existe una factura con este Nº de factura",
  INVOICE_RELATED_NOT_FOUND: "No se ha encontrado facturas relacionadas",
  PROVIDER_NOT_FOUND: "No se ha encontrado el proveedor",
  PROVIDER_ERROR_ACCOUNT: "Error con la cuenta del proveedor",
  PAYMENT_METHOD_NOT_FOUND: "No se ha encontrado el método de pago",
  INVOICE_TYPE_NOT_FOUND: "No se ha encontrado el tipo de factura",
  DEALER_NOT_FOUND: "No existe el local especificado",
  ERR_BAD_REQUEST: "Error inesperado. Por favor, intentelo de nuevo",
};

export const UNEXPECTED_ERROR =
  "Error inesperado. Por favor, intentelo de nuevo";

const getObjKey = (obj, value) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const getApiError = (err = undefined) => {
  if (err && API_ERRORS_CODE.includes(err)) {
    const apiErrorKey = getObjKey(API_ERRORS, err);
    return API_ERRORS_MESSAGES[apiErrorKey];
  }

  return UNEXPECTED_ERROR;
};
