import { config } from "../config/environment.config";
import { axiosInstance, instance } from "./BaseService";

export const getProviders = async (data) => {
    return axiosInstance.get(`${config.API_HOST}/provider`, {
      params: data,
    });
};

export const createProvider = async (formData) => {
    return axiosInstance.post(`${config.API_HOST}/provider`, formData);
};

export const getProvider = async (id) => {
    return axiosInstance.get(`${config.API_HOST}/provider/${id}`);
};

export const updateProvider = async (id, data) => {
    return axiosInstance.patch(`${config.API_HOST}/provider/${id}`, data);
};
