import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { createProvider } from "../../services/providers";

function ModalNewProvider({
  show,
  handleClose,
  handlerGetProviders,
  setNewProvider = undefined,
}) {
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [cif, setCif] = useState();
  const [iban, setIban] = useState();

  useEffect(() => {
    clearErrors(["conflictIban", "conflictCif", "conflictName"]);
  }, [name, cif, iban, clearErrors]);

  const onSubmitProvider = async (data) => {
    let arrIban = [];

    if (data?.items) {
      arrIban = data.items.map((item) => item.iban);
    }

    try {
      setLoading(true);
      const dataFiltered = Object.entries(data).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );
      const result = await createProvider({
        ...dataFiltered,
        fechaLimite: null,
        name: dataFiltered.name,
        identification: dataFiltered.documentation.toUpperCase(),
        zipCode: dataFiltered.cp,
        direction: dataFiltered.direction,
        iban: arrIban.length > 0 ? arrIban : null,
      });
      if (result) {
        setLoading(false);
        handlerGetProviders && handlerGetProviders();
        setNewProvider && setNewProvider(result);
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const errorHandler = (error) => {
    if (error.response.data.message.includes("name")) {
      setError("conflictName", {
        type: "name",
        message: "Proveedor ya existente",
      });
    } else if (error.response.data.message.includes("identification")) {
      setError("conflictCif", {
        type: "cif",
        message: "DNI/CIF/NIE ya existente",
      });
    } else {
      setError("conflictIban", { type: "iban", message: "IBAN Erróneo" });
    }
  };

  const handlerDeleteIban = (index) => {
    remove(index);
    clearErrors(["conflictIban"]);
  };

  return (
    <div>
      <form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="modal_header">
            <Modal.Title>Añadir Proveedor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <h6>Proveedor</h6>
                <Col className="my-1">
                  <input
                    className={`form-control ${errors.name && "is-invalid"} ${
                      errors.conflictName?.type === "name" && "is-invalid"
                    }`}
                    placeholder="Nombre"
                    {...register("name", {
                      required: true,
                      onChange: (e) => setName(e.target.value),
                      maxLength: 300,
                    })}
                  />
                  {errors.name && errors.name.type === "maxLength" && (
                    <span className="error">Máximo 300 caracteres</span>
                  )}
                  {errors.name?.type === "required" && (
                    <span className="error">Introduzca Nombre</span>
                  )}
                  {errors.conflictName?.type === "name" && (
                    <span className="error">{errors.conflictName.message}</span>
                  )}
                </Col>
                <Col className="my-1">
                  <input
                    className={`form-control ${
                      errors.documentation && "is-invalid"
                    } ${errors.conflictCif?.type === "cif" && "is-invalid"} `}
                    placeholder="CIF/DNI/NIE"
                    {...register("documentation", {
                      onChange: (e) => setCif(e.target.value),
                      required: true,
                      minLength: 9,
                    })}
                  />
                  {errors.documentation &&
                    errors.documentation.type === "minLength" && (
                      <span className="error">Mínimo 9 caracteres</span>
                    )}
                  {errors.documentation?.type === "required" && (
                    <span className="error">Introduzca CIF/DNI/NIE</span>
                  )}
                  {errors.conflictCif?.type === "cif" && (
                    <span className="error">{errors.conflictCif.message}</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xl={6} className="my-1">
                  <input
                    className={`form-control ${errors.cp && "is-invalid"} `}
                    placeholder="Codigo Postal"
                    {...register("cp", {
                      required: true,
                      valueAsNumber: true,
                      minLength: 5,
                      maxLength: 5,
                    })}
                  />
                  {errors.cp && errors.cp.type === "minLength" && (
                    <span className="error">Mínimo 5 dígitos</span>
                  )}
                  {errors.cp && errors.cp.type === "maxLength" && (
                    <span className="error">Máximo 5 dígitos</span>
                  )}
                  {errors.cp?.type === "required" && (
                    <span className="error">Codigo Postal</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="my-1">
                  <input
                    className={`form-control ${
                      errors.direction && "is-invalid"
                    } `}
                    placeholder="Dirección"
                    {...register("direction", { required: true })}
                  />
                  {errors.direction?.type === "required" && (
                    <span className="error">Introduzca Dirección</span>
                  )}
                </Col>
              </Row>
              {React.Children.toArray(
                fields.map(({ iban }, index) => {
                  return (
                    <Row className="mt-2">
                      <Col xl={9} className="my-1">
                        <input
                          placeholder="Introduzca IBAN"
                          className={`form-control ${
                            errors.iban && "is-invalid"
                          } ${errors.conflictIban?.type === "iban"}`}
                          name={`items[${index}].iban`}
                          defaultValue={iban}
                          {...register(`items[${index}].iban`, {
                            required: true,
                            minLength: 5,
                            maxLength: 34,
                            onChange: (e) => setIban(e.target.value),
                          })}
                        />
                        {errors.cp && errors.cp.type === "minLength" && (
                          <span className="error">
                            Mínimo 5 dígitos/caracteres
                          </span>
                        )}
                        {errors.cp && errors.cp.type === "maxLength" && (
                          <span className="error">
                            Máximo 34 dígitos/caracteres
                          </span>
                        )}
                        {errors.conflictIban?.type === "iban" && (
                          <span className="error">
                            {errors.conflictIban.message}
                          </span>
                        )}
                      </Col>
                      <Col xl={2} className="my-1">
                        <Button
                          variant="danger"
                          type="button"
                          onClick={() => {
                            handlerDeleteIban(index);
                          }}
                        >
                          Borrar
                        </Button>
                      </Col>
                    </Row>
                  );
                })
              )}
              <Button
                className="btn_custom my-3"
                type="button"
                onClick={() => append({ iban: "" })}
              >
                Añadir IBAN
              </Button>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Container fluid>
              <Row className="justify-content-between">
                <Col>
                  {!loading ? (
                    <Button
                      className="btn_custom"
                      onClick={handleSubmit(onSubmitProvider)}
                    >
                      Guardar
                    </Button>
                  ) : (
                    <Button className="btn_custom" disabled>
                      Subiendo...
                      <Spinner
                        variant="warning"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
      </form>
    </div>
  );
}

export default ModalNewProvider;
