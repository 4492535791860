const validationDNI = (dni) => {
  const regexDNI = /^\d{8}[a-zA-Z]$/;
  let validChars = "TRWAGMYFPDXBNJZSQVHLCKET";

  if (regexDNI.test(dni) === true) {
    let numero = dni.substr(0, dni.length - 1) % 23;
    let letra = dni.substr(dni.length - 1, 1);
    validChars = validChars.substring(numero, numero + 1);
    if (validChars !== letra.toUpperCase()) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export default validationDNI;
