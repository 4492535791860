import { postalCodeEuRegex, postalCodePtRegex } from "../utils/constants/regex";

const validationPostalCode = (cp) => {
  if (typeof cp !== "string") return false;
  if (cp.length === 8) return postalCodePtRegex.test(cp);
  if (cp.length === 5) return postalCodeEuRegex.test(cp);

  return false;
};

export default validationPostalCode;
