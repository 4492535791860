import validationDNI from "./validationDNI";

const validationNIE =  (nie)=> {

  // Change the initial letter for the corresponding number and validate as DNI
  let nie_prefix = nie.charAt(0);

  switch (nie_prefix) {
    case 'X': nie_prefix = 0; break;
    case 'Y': nie_prefix = 1; break;
    case 'Z': nie_prefix = 2; break;
    default: break;
  }

  return validationDNI(nie_prefix + nie.substr(1));

};
export default validationNIE;