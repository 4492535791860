import { config } from "../config/environment.config";
import { axiosInstance } from "./BaseService";

export const getDomain = async (data) => {
  try {
    return axiosInstance.get(
      `${config.API_HOST}/domain/${data}`
    );
  } catch (error) {}
};

export const getDomains = async (data) => {
  try {
    return axiosInstance.get(`${config.API_HOST}/domain`, {
      params: data,
    });
  } catch (error) {}
};

export const createDomain = async (formData) => {
  try {
    return axiosInstance.post(
      `${config.API_HOST}/domain`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {}
};

export const updateDomain = async (data) => {
  try {
    return axiosInstance.patch(
      `${config.API_HOST}/domain/${data.id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {}
};

export const deleteDomain = async (domainId, taskId) => {
  try {
    return axiosInstance.delete(
      `${config.API_HOST}/domain/${domainId}?taskId=${taskId}`
    );
  } catch (error) {}
};

export const updatePaymentDomain = async (data) => {
  try {
    return axiosInstance.patch(
      `${config.API_HOST}/domain/payment`,
      data
    );
  } catch (error) {}
};
