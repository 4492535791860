import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { errorToast } from "../../helpers/toastFunction";
import { getProviders } from "../../services/providers";
import {
  API_ERRORS,
  BUTTON_STATES,
  getApiError,
  INVOICE_FIELDS,
} from "./constants";
import Errors from "./errors";
import { validation } from "./validation";

const ProviderFrom = ({
  providerInfo,
  setProviderInfo,
  newProvider,
  setShowNewProvider,
}) => {
  const [providerBtn, setProviderBtn] = useState(BUTTON_STATES.CHECK_PROVIDER);

  const hasNewProvider = !!newProvider;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      baseIvaFields: [{ base: "", iva: "" }],
    },
    mode: "onChange",
  });

  const checkProvider = async (providerIdentification) => {
    try {
      const response = await getProviders({
        identification: providerIdentification,
      });

      if (response.data.length > 0) {
        setProviderInfo(response.data[0]);
      } else {
        errorToast(getApiError(API_ERRORS.PROVIDER_NOT_FOUND));
        setProviderBtn(BUTTON_STATES.ADD_PROVIDER);
        setProviderInfo(undefined);
      }
    } catch (error) {
      errorToast(getApiError(error.code));
    }
  };

  const onSubmit = (data) => {
    if (providerBtn === BUTTON_STATES.CHECK_PROVIDER) {
      checkProvider(data.identification);
    }
    if (providerBtn === BUTTON_STATES.ADD_PROVIDER) {
      setShowNewProvider(true);
    }
  };

  useEffect(() => {
    if (hasNewProvider) {
      setProviderBtn(BUTTON_STATES.CHECK_PROVIDER);
      setValue(INVOICE_FIELDS.identification.name, newProvider.identification)
      setProviderInfo(newProvider)
    }
  }, [hasNewProvider, newProvider, setProviderInfo, setValue]);

  return (
    <Container>
      <form key={"providerForm"} onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="col-12">
            <div className={`clearfix mt-3 mb-3 containerTitle`}>
              <span className="tittle ml-4">Datos del proveedor</span>
            </div>
          </Col>
          <Col className="col-3">
            <h6>CIF, DNI o NIE</h6>
            <input
              className={"form-control"}
              type="text"
              {...register(INVOICE_FIELDS.identification.name, {
                required: true,
                validate: (value) =>
                  validation(INVOICE_FIELDS.identification.name, value),
                onChange: () => setProviderBtn(BUTTON_STATES.CHECK_PROVIDER),
              })}
            />
            {errors[INVOICE_FIELDS.identification.name] && (
              <Errors
                errors={errors}
                field={INVOICE_FIELDS.identification.name}
              />
            )}
          </Col>
          <Col className="col-4">
            <h6>Nombre</h6>
            <input
              disabled
              className={"form-control"}
              type="text"
              value={providerInfo?.name || ""}
            />
          </Col>
          <Col className="col-4">
            <h6>Dirección</h6>
            <input
              disabled
              className={"form-control"}
              type="text"
              value={providerInfo?.direction || ""}
            />
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col className="col-3">
            <Button
              className="custom_button"
              variant={
                providerBtn === BUTTON_STATES.CHECK_PROVIDER
                  ? "primary"
                  : "warning"
              }
              type="submit"
              disabled={ !isValid }
            >
              {providerBtn}
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default ProviderFrom;
