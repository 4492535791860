import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm} from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  createTask,
  getManagementTypes,
  getProcedureTypes,
  getTasks,
  getTaxesTypes,
  getVehicleOrigin,
  post,
} from "../../services/axiosCall";
import validationDNI from "../../helpers/validationDNI";
import validationIBAN from "../../helpers/validationIBAN";
import validationNIE from "../../helpers/validationNIE";

import "./AddTask.css";
import validationCIF from "../../helpers/validationCIF";
import validationPlate from "../../helpers/validationPlate";
import RolType from "../../constants/roles";
import OriginType from "../../constants/origin";
import { errorToast, warningToast } from "../../helpers/toastFunction";
import validationFrCIF from "../../helpers/validationFrCif";
import validationPostalCode from "../../helpers/validationPostalCode";
import { isDatePastOrToday } from "../../utils/isDatePastOrToday";
import { useUserContext } from "contexts/UserContext";

const AddTask = () => {
  const navigate = useNavigate();
  const { user } = useUserContext()

  const [brandFilter, setBrandFilter] = useState(null);
  const [brandSelected, setBrandSelected] = useState(null);
  const [localSelected, setLocalSelected] = useState(null);
  const [foreignPlate, setForeignPlate] = useState(false);
  const [plate, setPlate] = useState(null);

  const [modelFilter, setModelFilter] = useState(null);
  const [locals, setLocals] = useState(null);
  const [origen, setOrigen] = useState(false);
  const [vehicleOrigin, setVehicleOrigin] = useState([]);
  const [taxesTypes, setTaxesTypes] = useState([]);
  const [procedureTypes, setProcedureTypes] = useState([]);
  const [managementTypes, setManagementTypes] = useState([]);
  const [affiliates, setAffiliates] = useState([]);

  const [gestion, setGestion] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    register,
    setError,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });



  useEffect(() => {
    (async () => {
      try {
        if (localSelected) {
          const response = await post("/api/filtros", {
            dataCall: {
              data_query: "empresa_asociada",
              data_call: localSelected,
            },
          });
          const responseMapped = response?.map((item, i) => {
            return { label: item.nombre, value: item.id };
          });
          setAffiliates(
            user.roles.includes(RolType.CENTRAL_ADMIN) ||
              user.roles.includes(RolType.GENERAL_ADMIN)
              ? responseMapped
              : responseMapped.filter(
                  (item) =>
                    item.value === 26 || item.value === 27 || item.value === 28
                )
          );
        }
      } catch (error) {}
    })();
  }, [localSelected, user.roles]);

  useEffect(() => {
    (async () => {
      try {
        const query = `models?brand=${brandSelected}`;
        const response = await post("/api/jato", { query });
  
        const modelosFiltro = response?.map((item, i) => {
          return { label: item.name, value: i };
        });
        modelosFiltro.push({ label: "Modelo no encontrado", value: "other" });
        setModelFilter(modelosFiltro);
      } catch (error) {}
    })()
  }, [brandSelected]);

  const origenHandler = (e) => {
    setOrigen(e?.value);
  };

  const gestionHandler = (e) => {
    setGestion(e?.value);
  };

  const foreignPlateHandler = () => {
    setForeignPlate(!foreignPlate);
  };
  const plateHandler = (inputData) => setPlate(inputData);

  useEffect(() => {
    const checkPlate = async () => {
      try {
        if (plate) {
          const validate = validationPlate(plate, foreignPlate);
          if (!validate) {
            setError("plate", {
              type: "invalid",
              message: "Matrícula no válida.",
            });
          }
          if (validate) {
            clearErrors();
            try {
              const response = await getTasks(
                { plate: plate, userId: user.id, limit: 2, offset: 0 }
              );
              if (response.count > 0) {
                warningToast(
                  <span>
                    <span>Esta matrícula ya existe en el sistema.</span>
                    <Link
                      className="toast-link"
                      to={`/gestion/${response.data[0].id}`}
                    >
                      Ver gestión {response.data[0].id}
                    </Link>
                  </span>
                );
              }
            } catch (error) {
              // Handle any API call errors
              console.error("Error fetching tasks:", error);
            }
          }
        }
      } catch (error) {}
    };
    checkPlate();
  }, [plate, foreignPlate, setError, clearErrors, user]);

  useEffect(() => {
    (async() => {
      try {
        const responseLocals = await post("/api/filtros", {
          dataCall: { data_query: "locales_citar", data_call: null },
        });
  
  
        const localsFiltered = responseLocals?.filter(
          (elem) => user.dealerships && user.dealerships.includes(elem.id)
        );
  
        const localsMapped = localsFiltered?.map((item) => {
          return { label: item.nombre, value: item.id };
        });
        setLocals(localsMapped);
  
        const query = "brands";
        const response = await post("/api/jato", { query });
        const marcasFiltro = response.map((item, i) => {
          return { label: item.brand, value: i };
        });
        setBrandFilter(marcasFiltro);
  
        const vehicleOriginResponse = await getVehicleOrigin();
        setVehicleOrigin(vehicleOriginResponse);
  
        const taxesTypesResponse = await getTaxesTypes();
        setTaxesTypes(taxesTypesResponse);
  
        const procedureTypesResponse = await getProcedureTypes();
        setProcedureTypes(procedureTypesResponse);
  
        const managementTypesResponse = await getManagementTypes();
        setManagementTypes(managementTypesResponse);
      } catch (error) {
      }
    })()
    
  }, [user.dealerships]);

  const onSubmit = async (data) => {
    try {
      setIsDisabled(true);
      await createTask({
        ...data,
        numeroIdentificacion: data.numeroIdentificacion.toUpperCase(),
        matricula: data.matricula.toUpperCase(),
        vehiculoFormaPago: data.vehiculoFormaPago?.toUpperCase(),
        titular: `${data?.nombre} ${data?.apellido1} ${data?.apellido2}`,
        direccion: `${data.calle} nº${data.numero} ${
          data.piso ? data.piso : ""
        }`,
        gestion: data.gestion?.value,
        tramite: data.tramite?.value,
        origin: data.origin?.value,
        local: data.local?.value,
        society: data.society?.value,
        impuestos: data.impuestos?.value,
        marca: data.marca?.label,
        modelo: data.modelo?.label,
      });
      clearErrors();
      navigate("/home");
    } catch (error) {
      if (error.response.status === 409) {
        errorToast(
          <>
            <p className="toast-title">No es posible crear la gestión</p>
            <p className="toast-text">
              Esta matrícula y DNI ya están vinculados a otra gestión
            </p>
          </>
        );
      }
    }
  };

  const selectImpuestos = () => {
    const response = taxesTypes;

    if (origen === 1) {
      return [{ label: "REBU", value: 4 }];
    }
    return response;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Container>
          <Row>
            <Col className="col-12">
              <div className={`clearfix mt-3 mb-3 containerTitle`}>
                <span className="tittle ml-4">Añadir gestión</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <h6>Datos del vehículo:</h6>
            <Col xs={2} md={2} lg={2} xl={2}>
              <input
                className={`form-control ${
                  (errors.matricula || errors.plate) && "is-invalid"
                } `}
                placeholder="Matrícula"
                {...register("matricula", {
                  required: true,
                })}
                onChange={(e) => plateHandler(e.target.value)}
              />
              {errors.plate?.type === "required" && (
                <span className="error">Introduzca número de matrícula</span>
              )}
              {errors.plate?.type === "invalid" && (
                <span className="error">{errors.plate?.message}</span>
              )}

              <Form.Check
                type="checkbox"
                label="Vehículo importado"
                className="custom__label"
                onClick={foreignPlateHandler}
              />
            </Col>

            <Col>
              <input
                title=""
                className={`form-control ${errors.bastidor && "is-invalid"} `}
                placeholder="Bastidor"
                {...register("bastidor", {
                  required: true,
                  pattern: {
                    value: /^\b[(A-H|J-N|P|R-Z|0-9)]{17}\b$/i,
                  },
                })}
              />
              {errors.bastidor?.type === "required" && (
                <span className="error">Introduzca el número de bastidor</span>
              )}
              {errors.bastidor && errors.bastidor?.type !== "required" && (
                <span className="error">Introduzca un bastidor válido</span>
              )}
            </Col>
            <Col>
              <Controller
                name="marca"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    onBlur={setBrandSelected(field.value?.label)}
                    placeholder="Marca"
                    {...field}
                    options={brandFilter}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#fe9800",
                        primary25: "#ffc548",
                      },
                    })}
                  />
                )}
              />
              {errors.marca?.type === "required" && (
                <span className="error">Seleccione marca del vehículo</span>
              )}
            </Col>
            <Col>
              <Controller
                name="modelo"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    isDisabled={!brandSelected}
                    placeholder="Modelo"
                    {...field}
                    options={modelFilter}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#fe9800",
                        primary25: "#ffc548",
                      },
                    })}
                  />
                )}
              />
              {errors.modelo?.type === "required" && (
                <span className="error">Seleccione modelo del vehículo</span>
              )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={2} md={2} lg={2} xl={2}>
              <h6>Fecha contrato</h6>

              <input
                type="date"
                className={`form-control ${
                  errors.fechaContrato && "is-invalid"
                } `}
                placeholder="Fecha del contrato"
                {...register("fechaContrato", {
                  required: true,
                  pattern:
                    /^(19[7-9]\d|20\d{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                  validate: isDatePastOrToday,
                })}
              />
              {errors.fechaContrato?.type === "required" && (
                <span className="error">Introduzca la fecha del contrato.</span>
              )}
              {errors.fechaContrato &&
                errors.fechaContrato?.type !== "required" && (
                  <span className="error">
                    Introduzca Fecha del contrato Válida
                  </span>
                )}
            </Col>

            <Col xs={2} md={2} lg={2} xl={2}>
              <h6>Fecha 1ª Matriculación</h6>
              <input
                type="date"
                className={`form-control ${
                  errors.matriculacion && "is-invalid"
                } `}
                placeholder="Fecha 1ª matriculación"
                {...register("matriculacion", {
                  required: true,
                  pattern:
                    /^(19[7-9]\d|20\d{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                  validate: isDatePastOrToday,
                })}
              />
              {errors.matriculacion?.type === "required" && (
                <span className="error">
                  Introduzca fecha de la primera matriculación.
                </span>
              )}
              {errors.matriculacion &&
                errors.matriculacion?.type !== "required" && (
                  <span className="error">
                    Introduzca Fecha de la primera matriculación Válida
                  </span>
                )}
            </Col>
          </Row>

          <Row className="my-2 mt-4">
            <h6>Tipo de gestión:</h6>
            <Col>
              <Controller
                name="gestion"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    onBlur={gestionHandler(field.value)}
                    placeholder="Seleccione gestión"
                    {...field}
                    options={managementTypes}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#fe9800",
                        primary25: "#ffc548",
                      },
                    })}
                  />
                )}
              />
              {errors.gestion?.type === "required" && (
                <span className="error">Seleccione gestión</span>
              )}
            </Col>
            <Col>
              <Controller
                name="origin"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    onBlur={origenHandler(field.value)}
                    placeholder="Seleccione origen del vehículo"
                    {...field}
                    options={vehicleOrigin}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#fe9800",
                        primary25: "#ffc548",
                      },
                    })}
                  />
                )}
              />
              {errors.origin?.type === "required" && (
                <span className="error">Seleccione origen del vehículo</span>
              )}
            </Col>
            <Col>
              <Controller
                name="tramite"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    placeholder="Seleccione trámite"
                    {...field}
                    options={procedureTypes}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#fe9800",
                        primary25: "#ffc548",
                      },
                    })}
                  />
                )}
              />
              {errors.tramite?.type === "required" && (
                <span className="error">Seleccione trámite</span>
              )}
            </Col>
          </Row>
          <Row className="my-4">
            <h6>Datos del local:</h6>
            <Col>
              <Controller
                name="local"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    onBlur={setLocalSelected(field.value?.value)}
                    placeholder="Seleccione local"
                    {...field}
                    options={locals}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#fe9800",
                        primary25: "#ffc548",
                      },
                    })}
                  />
                )}
              />
              {errors.local?.type === "required" && (
                <span className="error">Seleccione concesionario</span>
              )}
            </Col>
            <Col>
              <Controller
                name="society"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    isDisabled={!localSelected}
                    placeholder="Seleccione sociedad"
                    {...field}
                    options={affiliates}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#fe9800",
                        primary25: "#ffc548",
                      },
                    })}
                  />
                )}
              />
              {errors.society?.type === "required" && (
                <span className="error">Seleccione sociedad</span>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <h6 title="Nombre y apellidos o razón social según permiso de circulación">
              Datos del cliente:
            </h6>
            <Col className="my-1">
              <input
                className={`form-control ${errors.nombre && "is-invalid"} `}
                placeholder={
                  origen === OriginType.COLLABORATING_COMPANY ||
                  origen === OriginType.NO_COLLABORATING_COMPANY
                    ? "Nombre de la empresa"
                    : "Nombre"
                }
                {...register("nombre", { required: true })}
              />
              {errors.nombre?.type === "required" && (
                <span className="error">Introduzca el nombre</span>
              )}
            </Col>

            {origen !== OriginType.COLLABORATING_COMPANY &&
              origen !== OriginType.NO_COLLABORATING_COMPANY && (
                <>
                  <Col className="my-1">
                    <input
                      className="form-control"
                      placeholder="Apellido 1"
                      {...register("apellido1")}
                    />
                  </Col>

                  <Col className="my-1">
                    <input
                      className="form-control"
                      placeholder="Apellido 2"
                      {...register("apellido2")}
                    />
                  </Col>
                </>
              )}
          </Row>
          <Row>
            <Col xs={4} md={4} lg={4} xl={4} className="my-1">
              <input
                className={`form-control ${
                  errors.numeroIdentificacion && "is-invalid"
                } `}
                placeholder={origen === 3 || origen === 4 ? "CIF" : "DNI/NIE"}
                {...register("numeroIdentificacion", {
                  required: true,
                  validate: (value) =>
                    origen === OriginType.COLLABORATING_COMPANY ||
                    origen === OriginType.NO_COLLABORATING_COMPANY
                      ? validationCIF(value) || validationFrCIF(value)
                      : validationDNI(value)
                      ? true
                      : validationNIE(value),
                })}
              />
              {errors.numeroIdentificacion?.type === "required" && (
                <span className="error">Introduzca número de DNI/NIE/CIF</span>
              )}
              {errors.numeroIdentificacion &&
                errors.numeroIdentificacion?.type !== "required" &&
                errors.numeroIdentificacion?.type !== "custom" && (
                  <span className="error">
                    {origen === OriginType.COLLABORATING_COMPANY ||
                    origen === OriginType.NO_COLLABORATING_COMPANY
                      ? "El CIF introducido es incorrecto"
                      : "El DNI/NIE introducido es incorrecto"}
                  </span>
                )}
            </Col>
            <Col xs={8} md={8} lg={8} xl={8} className="my-1">
              <input
                className={`form-control ${errors.iban && "is-invalid"} `}
                placeholder="IBAN"
                {...register("iban", {
                  required: true,
                  validate: (value) => validationIBAN(value),
                })}
              />
              {errors.iban?.type === "required" && (
                <span className="error">Introduzca número de IBAN</span>
              )}
              {errors.iban && errors.iban?.type !== "required" && (
                <span className="error">El IBAN introducido es incorrecto</span>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <h6>Dirección:</h6>
            <Col xs={6} md={6} lg={6} xl={6} className="my-1">
              <input
                className={`form-control ${errors.calle && "is-invalid"} `}
                placeholder="Calle, Avenida, etc..."
                {...register("calle", { required: true })}
              />
              {errors.calle?.type === "required" && (
                <span className="error">Introduzca la dirección</span>
              )}
            </Col>
            <Col xs={2} md={2} lg={2} xl={2} className="my-1">
              <input
                type="number"
                className={`form-control ${errors.numero && "is-invalid"} `}
                placeholder="Número"
                {...register("numero", { required: true })}
              />
              {errors.numero?.type === "required" && (
                <span className="error">Introduzca número</span>
              )}
            </Col>
            <Col xs={2} md={2} lg={2} xl={2} className="my-1">
              <input
                type="string"
                className={`form-control ${errors.piso && "is-invalid"} `}
                placeholder="Piso"
                {...register("piso", { required: false })}
              />
              {errors.piso?.type === "required" && (
                <span className="error">Introduzca piso</span>
              )}
            </Col>
            <Col xs={2} md={2} lg={2} xl={2} className="my-1">
              <input
                type="text"
                className={`form-control ${errors.zip && "is-invalid"} `}
                placeholder="Codigo postal"
                {...register("zip", {
                  required: true,
                  validate: (value) => validationPostalCode(value),
                })}
              />
              {errors.zip?.type === "required" && (
                <span className="error">Introduzca el código postal</span>
              )}
              {errors.zip && errors.zip?.type !== "required" && (
                <span className="error">
                  Introduzca un código postal válido
                </span>
              )}
            </Col>
          </Row>

          <Row className="mt-4">
            <h6 title="Nombre y apellidos o razón social según permiso de circulación">
              Importes:
            </h6>
            <Col xs={3} md={3} lg={3} xl={3} className="my-1">
              <input
                type="number"
                step="any"
                title="Importe total, según aparece en el contrato."
                className={`form-control ${errors.importe && "is-invalid"} `}
                placeholder="Importe de compra"
                {...register("importe", { required: true })}
              />
              {errors.importe?.type === "required" && (
                <span className="error">
                  Introduzca el importe total de la compra
                </span>
              )}
            </Col>

            <Col xs={3} md={3} lg={3} xl={3} className="my-1">
              <Controller
                name="impuestos"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    placeholder="Seleccione impuesto"
                    {...field}
                    options={selectImpuestos()}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#fe9800",
                        primary25: "#ffc548",
                      },
                    })}
                  />
                )}
              />
              {errors.impuestos?.type === "required" && (
                <span className="error">Seleccione impuesto</span>
              )}
            </Col>

            <Col xs={3} md={3} lg={3} xl={3} className="my-1">
              <input
                type="number"
                step="any"
                title="Importe de ajuste (forma de pago, etc...)"
                className={`form-control ${errors.importe && "is-invalid"} `}
                placeholder="Importe de ajuste"
                {...register("ajuste", {
                  required: gestion === 4 ? true : false,
                })}
              />
              {errors.importe?.type === "required" && (
                <span className="error">Introduzca el importe de ajuste</span>
              )}
            </Col>

            {gestion === 4 && (
              <Col xs={3} md={3} lg={3} xl={3}>
                <input
                  className={`form-control ${
                    errors.vehiculoFormaPago && "is-invalid"
                  } my-1`}
                  placeholder="Matrícula vehículo forma de pago"
                  {...register("vehiculoFormaPago", {
                    required: gestion === 4 ? true : false,
                    pattern: {
                      value: /^\d{4}?[ -]*([A-Z]{3})$/i,
                    },
                  })}
                />
                {errors.vehiculoFormaPago?.type === "required" && (
                  <span className="error">Introduzca número de matrícula</span>
                )}
                {errors.vehiculoFormaPago &&
                  errors.vehiculoFormaPago?.type !== "required" && (
                    <span className="error">La matrícula es incorrecta.</span>
                  )}
              </Col>
            )}
          </Row>

          <Row className="my-5">
            <Button className="btn_custom" type="submit" disabled={isDisabled}>
              Añadir
            </Button>
          </Row>
        </Container>
      </form>
    </div>
  );
};

export default AddTask;
