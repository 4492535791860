import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import { FiUpload } from "react-icons/fi";
import { useFieldArray, useForm, Controller, useWatch } from "react-hook-form";
import {
  CATEGORY_OPTIONS,
  IRPF_OPTIONS,
  IVA_OPTIONS,
  PAYMENT_METHODS,
} from "../../constants/invoice";
import { getApiError, INVOICE_FIELDS } from "./constants";
import Errors from "./errors";
import { priceWithTaxes, validateInvoices } from "./validation";
import { getFilters } from "../../services/locales.service";
import { errorToast } from "../../helpers/toastFunction";
import RenderPDF from "../../components/RenderPDF";

const InvoiceForm = ({
  providerInfo,
  handleShowEditDocumentation,
  invoiceSubmit,
  fileState,
  isSubmitting
}) => {
  const [withHoldingTax, setWithHoldingTax] = useState(0);
  const [localesOptions, setLocalesOptions] = useState([]);
  const [societyOptions, setSocietyOptions] = useState([]);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [INVOICE_FIELDS.baseIvaFields.name]: [
        {
          [INVOICE_FIELDS.base.name]: 0,
          [INVOICE_FIELDS.iva.name]: null,
          [INVOICE_FIELDS.ivaResult.name]: 0,
        },
      ],
      invoiceFile: "",
      comments: null,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: INVOICE_FIELDS.baseIvaFields.name,
  });

  const watchedBaseIvaFields = useWatch({
    control,
    name: INVOICE_FIELDS.baseIvaFields.name,
  });

  const watchedIRPF = useWatch({
    control,
    name: INVOICE_FIELDS.irpf.name,
  });

  const watchedTotalAmount = useWatch({
    control,
    name: INVOICE_FIELDS.totalAmount.name,
  });

  const watchedLocales = useWatch({
    control,
    name: INVOICE_FIELDS.local.name,
  });

  const watchedSociety = useWatch({
    control,
    name: INVOICE_FIELDS.society.name,
  });

  const getLocalesOptions = async () => {
    try {
      const responseLocale = await getFilters({
        data_query: "locales_citar",
        data_call: null,
      });

      const responseLocaleMapped = responseLocale.map((locale) => ({
        label: locale.nombre,
        value: locale.id,
      }));

      setLocalesOptions(responseLocaleMapped);
    } catch (error) {
      errorToast(getApiError(error.code));
    }
  };

  const getSocietyOptions = async (localId) => {
    try {
      const responseLocale = await getFilters({
        data_query: "empresa_asociada",
        data_call: localId,
      });

      const responseSocietyMapped = responseLocale.map((society) => ({
        label: society.nombre,
        value: society.id,
      }));

      setSocietyOptions(responseSocietyMapped);
    } catch (error) {
      errorToast(getApiError(error.code));
    }
  };

  const isFieldDisabled = (field = "default") => {
    let disabled = true;

    providerInfo && (disabled = false);

    if (field === INVOICE_FIELDS.local.name) {
      disabled = true;
      const totalAmount = parseFloat(watchedTotalAmount);

      if (
        !isNaN(totalAmount) &&
        totalAmount > 0 &&
        !errors[INVOICE_FIELDS.totalAmount.name]
      ) {
        disabled = false;
      }
    }

    if (field === INVOICE_FIELDS.society.name) {
      disabled = true;
      watchedLocales && (disabled = false);
    }

    if (field === INVOICE_FIELDS.payment.name) {
      disabled = true;
      watchedSociety && (disabled = false);
    }

    return disabled;
  };

  const getResult = (index) => {
    const { base, iva } = watchedBaseIvaFields[index];
    const result = priceWithTaxes(base, iva);
    return result && result.toFixed(2);
  };

  useEffect(() => {
    if (watchedBaseIvaFields) {
      const allBases = watchedBaseIvaFields.reduce(
        (acc, item) => {
          return parseFloat(acc) + parseFloat(item.base);
        },
        [0]
      );

      const calculateIRPF =
        priceWithTaxes(allBases, watchedIRPF) - parseFloat(allBases);

      setWithHoldingTax(calculateIRPF.toFixed(2) || 0);
    }
  }, [watchedBaseIvaFields, watchedIRPF]);

  useEffect(() => {
    if (providerInfo && !errors[INVOICE_FIELDS.totalAmount.name]) {
      getLocalesOptions();
    }
  }, [errors, providerInfo]);

  useEffect(() => {
    if (watchedLocales) {
      getSocietyOptions(watchedLocales);
    }
  }, [watchedLocales]);

  useEffect(() => {
    if (!fileState) {
      return;
    }
    setValue(INVOICE_FIELDS.invoiceFile.name, fileState.name);
    return;
  }, [fileState, setValue, clearErrors]);

  const onSubmit = (data) => {
    /**
     * NOTE: delete invoiceFile field from witch is necessary to check if 
     * the form has any file in register object of this scope and to
     * show error messages
     */
    data.invoiceFile && delete data.invoiceFile;

    !data.comments && delete data.comments

    invoiceSubmit(data)
  }

  return (
    <form
      key={2}
      encType="multipart/form-data"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Container>
        <Row>
          <Col className="col-12">
            <div className={`clearfix mt-3 mb-3 containerTitle`}>
              <span className="tittle ml-4">Añadir Factura</span>
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col className="col-3">
            <h6> Fecha factura</h6>
            <input
              type="date"
              disabled={isFieldDisabled()}
              className={`form-control ${
                errors[INVOICE_FIELDS.date.name] && "is-invalid"
              }`}
              {...register(INVOICE_FIELDS.date.name, {
                required: true,
              })}
            />
            {errors[INVOICE_FIELDS.date.name] && (
              <Errors errors={errors} field={INVOICE_FIELDS.date.name} />
            )}
          </Col>

          <Col className="col-3">
            <h6>Nº Factura</h6>
            <input
              className={`form-control ${
                errors[INVOICE_FIELDS.invoiceId.name] && "is-invalid"
              }`}
              placeholder="123456789"
              disabled={isFieldDisabled()}
              type="text"
              {...register(INVOICE_FIELDS.invoiceId.name, {
                required: true,
              })}
            />
            {errors[INVOICE_FIELDS.invoiceId.name] && (
              <Errors errors={errors} field={INVOICE_FIELDS.invoiceId.name} />
            )}
          </Col>

          <Col className="col-3">
            <h6>Categorías</h6>
            <Controller
              control={control}
              name={INVOICE_FIELDS.category.name}
              options={CATEGORY_OPTIONS}
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  required={true}
                  inputRef={ref}
                  value={CATEGORY_OPTIONS.find((c) => c.value === value)}
                  onChange={(selectedOption) => onChange(selectedOption.value)}
                  options={CATEGORY_OPTIONS}
                  isDisabled={isFieldDisabled()}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#fe9800",
                      primary25: "#ffc548",
                    },
                  })}
                />
              )}
            />
            {errors[INVOICE_FIELDS.category.name] && (
              <Errors errors={errors} field={INVOICE_FIELDS.category.name} />
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="col-12">
            <div className={`clearfix mt-3 mb-2 containerTitle`}>
              <span className="tittle ml-4">Desglose Factura</span>
            </div>
          </Col>
          <Col className="col-12 mb-1">
            <span>
              Puedes añadir tantas líneas de base + IVA como tenga tu factura.
            </span>
          </Col>
        </Row>
        {React.Children.toArray(
          fields.map((field, index) => {
            return (
              <Row className="mt-2">
                <Col className="col-3">
                  <h6>Base</h6>
                  <input
                    name={`${INVOICE_FIELDS.baseIvaFields.name}[${index}].base`}
                    className={`form-control ${
                      errors[INVOICE_FIELDS.baseIvaFields.name]?.[index] &&
                      errors[INVOICE_FIELDS.baseIvaFields.name][index]?.base &&
                      "is-invalid"
                    }`}
                    placeholder="0"
                    step={0.01}
                    type="number"
                    disabled={isFieldDisabled()}
                    min={0}
                    {...register(
                      `${INVOICE_FIELDS.baseIvaFields.name}[${index}].base`,
                      {
                        required: true,
                        valueAsNumber: true,
                        validate: (value) => parseFloat(value) > 0,
                      }
                    )}
                  />
                  {errors[INVOICE_FIELDS.baseIvaFields.name]?.[index]?.base && (
                    <Errors
                      isFieldArray
                      errors={errors}
                      field={INVOICE_FIELDS.baseIvaFields.name}
                      fieldArrayIndex={index}
                      fieldArrayName={INVOICE_FIELDS.baseIvaFields.base.name}
                    />
                  )}
                </Col>
                <Col className="col-3">
                  <h6>IVA</h6>
                  <Controller
                    control={control}
                    key={index}
                    name={`${INVOICE_FIELDS.baseIvaFields.name}[${index}].iva`}
                    rules={{ required: true }}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <Select
                          key={index}
                          required={true}
                          options={IVA_OPTIONS}
                          {...field}
                          value={IVA_OPTIONS.find((c) => c.value === value)}
                          onChange={(selectedOption) => {
                            onChange(selectedOption.value);
                            const resultValue = getResult(index);
                            const resultFormId = `${INVOICE_FIELDS.baseIvaFields.name}[${index}].${INVOICE_FIELDS.ivaResult.name}`;
                            setValue(resultFormId, parseFloat(resultValue));
                          }}
                          isDisabled={isFieldDisabled()}
                        />
                      );
                    }}
                  />
                  {errors[INVOICE_FIELDS.baseIvaFields.name]?.[index]?.iva && (
                    <Errors
                      isFieldArray
                      errors={errors}
                      field={INVOICE_FIELDS.baseIvaFields.name}
                      fieldArrayIndex={index}
                      fieldArrayName={INVOICE_FIELDS.baseIvaFields.iva.name}
                    />
                  )}
                </Col>
                <Col className="col-3">
                  <h6>Resultado</h6>
                  <Controller
                    control={control}
                    key={index}
                    name={`${INVOICE_FIELDS.baseIvaFields.name}[${index}].${INVOICE_FIELDS.ivaResult.name}`}
                    rules={{
                      validate: (value) =>
                        validateInvoices(
                          INVOICE_FIELDS.ivaResult.name,
                          value,
                          index,
                          watchedBaseIvaFields
                        ),
                    }}
                    render={({ field }) => {
                      const { onChange, value } = field;
                      return (
                        <input
                          type="number"
                          className={`form-control ${
                            errors[INVOICE_FIELDS.baseIvaFields.name]?.[index]
                              ?.result && "is-invalid"
                          }`}
                          disabled={isFieldDisabled()}
                          {...field}
                          key={index}
                          step={0.01}
                          value={value}
                          onChange={(e) => {
                            const value = e.target.value;
                            return onChange(value);
                          }}
                        />
                      );
                    }}
                  />
                  {errors[INVOICE_FIELDS.baseIvaFields.name]?.[index]
                    ?.result && (
                    <Errors
                      isFieldArray
                      errors={errors}
                      field={INVOICE_FIELDS.baseIvaFields.name}
                      fieldArrayIndex={index}
                      fieldArrayName={
                        INVOICE_FIELDS.baseIvaFields.ivaResult.name
                      }
                    />
                  )}
                </Col>
                {index > 0 && (
                  <Col className="col-3 d-flex justify-content-center align-items-end">
                    <Button variant="danger" onClick={() => remove(index)}>
                      Eliminar Fila
                    </Button>
                  </Col>
                )}
              </Row>
            );
          })
        )}
        <Row className="mt-3">
          <Col className="col-12 d-flex justify-content-start align-items-center">
            <Button
              className="btn_custom"
              onClick={() =>
                append({
                  [INVOICE_FIELDS.base.name]: 0,
                  [INVOICE_FIELDS.iva.name]: 0,
                  [INVOICE_FIELDS.ivaResult.name]: 0,
                })
              }
              disabled={isFieldDisabled()}
            >
              Añadir Base + Iva
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-3">
            <h6>IRPF</h6>
            <Controller
              control={control}
              name={INVOICE_FIELDS.irpf.name}
              options={IRPF_OPTIONS}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  required={true}
                  inputRef={ref}
                  value={IRPF_OPTIONS.find((c) => c.value === value)}
                  onChange={(selectedOption) => {
                    return onChange(selectedOption.value);
                  }}
                  options={IRPF_OPTIONS}
                  isDisabled={isFieldDisabled()}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#fe9800",
                      primary25: "#ffc548",
                    },
                  })}
                />
              )}
            />
            {errors[INVOICE_FIELDS.irpf.name] && (
              <Errors errors={errors} field={INVOICE_FIELDS.irpf.name} />
            )}
          </Col>
          <Col className="col-3">
            <h6>Retención IRPF</h6>
            <input
              name="resultIrpf"
              className={"form-control"}
              type="number"
              placeholder="0"
              value={withHoldingTax}
              disabled
            />
          </Col>

          <Col className="col-3">
            <h6>Total a Pagar</h6>
            <input
              name={INVOICE_FIELDS.totalAmount.name}
              className={"form-control"}
              type="number"
              step={0.01}
              placeholder="Resultado Total"
              disabled={isFieldDisabled()}
              {...register(INVOICE_FIELDS.totalAmount.name, {
                required: true,
                valueAsNumber: true,
                validate: (value) =>
                  validateInvoices(
                    INVOICE_FIELDS.totalAmount.name,
                    value,
                    0,
                    watchedBaseIvaFields,
                    watchedIRPF
                  ),
              })}
            />
            {errors[INVOICE_FIELDS.totalAmount.name] && (
              <Errors errors={errors} field={INVOICE_FIELDS.totalAmount.name} />
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="col-12">
            <div className={`clearfix mt-3 mb-2 containerTitle`}>
              <span className="tittle ml-4">Sociedades</span>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-3">
            <h6>Tiendas</h6>
            <Controller
              control={control}
              name={INVOICE_FIELDS.local.name}
              options={localesOptions}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, ref } }) => (
                <Select
                  required={true}
                  inputRef={ref}
                  onChange={(selectedOption) => {
                    return onChange(selectedOption.value);
                  }}
                  options={localesOptions}
                  isDisabled={isFieldDisabled(INVOICE_FIELDS.local.name)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#fe9800",
                      primary25: "#ffc548",
                    },
                  })}
                />
              )}
            />
            {errors[INVOICE_FIELDS.local.name] && (
              <Errors errors={errors} field={INVOICE_FIELDS.local.name} />
            )}
          </Col>
          <Col className="col-3">
            <h6>Sociedades</h6>
            <Controller
              control={control}
              name={INVOICE_FIELDS.society.name}
              options={societyOptions}
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  required={true}
                  inputRef={ref}
                  onChange={(selectedOption) => {
                    return onChange(selectedOption.value);
                  }}
                  options={societyOptions}
                  isDisabled={isFieldDisabled(INVOICE_FIELDS.society.name)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#fe9800",
                      primary25: "#ffc548",
                    },
                  })}
                />
              )}
            />
            {errors[INVOICE_FIELDS.society.name] && (
              <Errors errors={errors} field={INVOICE_FIELDS.society.name} />
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="col-12">
            <div className={`clearfix mt-3 mb-2 containerTitle`}>
              <span className="tittle ml-4">Pago</span>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="col-3">
            <h6>Método de Pago</h6>
            <Controller
              control={control}
              name={INVOICE_FIELDS.payment.name}
              options={PAYMENT_METHODS}
              rules={{ required: true }}
              render={({ field: { onChange, ref } }) => (
                <Select
                  required={true}
                  inputRef={ref}
                  onChange={(selectedOption) => {
                    return onChange(selectedOption.value);
                  }}
                  options={PAYMENT_METHODS}
                  isDisabled={isFieldDisabled(INVOICE_FIELDS.payment.name)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#fe9800",
                      primary25: "#ffc548",
                    },
                  })}
                />
              )}
            />
            {errors[INVOICE_FIELDS.payment.name] && (
              <Errors errors={errors} field={INVOICE_FIELDS.payment.name} />
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="col-12">
            <div className={`clearfix mt-3 mb-2 containerTitle`}>
              <span className="tittle ml-4">PDF Factura</span>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className="col-3">
            <h6>PDF Factura</h6>
            <input
              type="text"
              className="d-none"
              disabled
              {...register(INVOICE_FIELDS.invoiceFile.name, {
                required: true,
                validate: (value) => value !== "",
              })}
            />
            {errors[INVOICE_FIELDS.invoiceFile.name] && (
              <Errors errors={errors} field={INVOICE_FIELDS.invoiceFile.name} />
            )}
          </Col>
          <Col className="col-3">
            <FiUpload
              onClick={() => handleShowEditDocumentation()}
              size={30}
              color={"#E88A00"}
              className=" mx-1 cursor_pointer"
            />
          </Col>
        </Row>
        {providerInfo && fileState && (
          <Row className="mt-3">
            <Col className="col-12">
              <RenderPDF data={fileState} />
            </Col>
          </Row>
        )}

        <Row>
          <Col className="col-12">
            <div className={`clearfix mt-3 mb-3 containerTitle`}>
              <span className="tittle ml-4">Notas</span>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className="col-3">
            <h6>Observaciones</h6>
            <input
              className={"form-control"}
              type="text"
              placeholder="Observaciones"
              disabled={isFieldDisabled()}
              defaultValue={""}
              {...register(INVOICE_FIELDS.comments.name)}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className="col-3">
            <Button type="submit" disabled={isFieldDisabled() || isSubmitting}>
              Subir
            </Button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default InvoiceForm;
