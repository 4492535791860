const validationFrCIF = (cif) => {
  const cifNumber = Number(cif.slice(2));

  if (cif.length === 13 || cif.length === 11) {
    if (Number.isNaN(cifNumber)) return false;
    if (cif.slice(0, 2).toUpperCase() === "FR") return true;
  }
  return false;
};

export default validationFrCIF;
