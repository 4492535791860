import { useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteTask } from "../../services/axiosCall";
import "./index.module.css";

const DeleteModal = (props) => {
  const { show, handleClose, task } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const deleteHandler = async () => {
    try {
      setLoading(true);
      const result = await deleteTask(task.id);
      if (result) {
        setLoading(false);
        handleClose();
        navigate("/home");
      }
    } catch (error) {}
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header onHide={handleClose} className="modal_header">
          <h5>{`Eliminar gestión ${task?.id}`}</h5>
        </Modal.Header>
        <Modal.Body>
          Pulse eliminar para confirmar el borrado de la gestión.
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-center">
              {!loading ? (
                <Button className="btn_custom" onClick={deleteHandler}>
                  Eliminar
                </Button>
              ) : (
                <Button className="btn_custom" disabled>
                  Eliminando...
                  <Spinner
                    variant="warning"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              )}
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteModal;
