import validationCIF from "../../helpers/validationCIF";
import validationDNI from "../../helpers/validationDNI";
import validationFrCIF from "../../helpers/validationFrCif";
import { INVOICE_FIELDS } from "./constants";

export const priceWithTaxes = (base, tax) => {
  const parsedBase = parseFloat(base);
  const parsedTax = parseFloat(tax) / 100;
  return parsedBase + parsedBase * parsedTax;
};

export const validation = (field, value) => {
  if (field === INVOICE_FIELDS.identification.name) {
   
    if (validationCIF(value) || validationDNI(value)) {
      return true;
    }
    if(validationFrCIF(value)){
      return true
    }
    return false;
  }
};


export const validateInvoices = (field, value, index, allBaseIva, irpf = 0) => {
  if (field === INVOICE_FIELDS.ivaResult.name) {
    const result = priceWithTaxes(
      allBaseIva[index].base,
      allBaseIva[index].iva
    );

    const roundingUp = (result + 0.01).toFixed(2);
    const roundingDown = (result - 0.01).toFixed(2);
    const fixedResult = result.toFixed(2)
    const fixedValue = parseFloat(value).toFixed(2);

    const isUp = fixedValue === roundingUp;
    const isDown = fixedValue === roundingDown;
    const isEqual = fixedValue === fixedResult;

    /**
     * NOTE: 
     * we are checking if the value (named result) of price plus vat is equal to the value entered by the user
     * whether it is rounded up or down by 1 cent 
     * and also whether it is equal to result without any rounding
     */
    return allBaseIva && (
      isUp || isDown || isEqual
    )
  }

  if (field === INVOICE_FIELDS.totalAmount.name) {
    if (!value || !allBaseIva.length > 0) {
      return false;
    }

    const allPrices = allBaseIva.reduce((acc, item) => {
      if (item[INVOICE_FIELDS.ivaResult.name]!== 0) {
        return parseFloat(acc) + parseFloat(item[INVOICE_FIELDS.ivaResult.name]);
      }
      return acc;
    }, 0);

    const allBases = allBaseIva.reduce((acc, item) => {
      if (item.base !== "") {
        return parseFloat(acc) + parseFloat(item.base);
      }
      return acc;
    }, 0);

    const totalIprfsDeducted = parseFloat(allBases) * (irpf / 100);

    const totalAmount = parseFloat(allPrices) - parseFloat(totalIprfsDeducted);

    return parseFloat(value).toFixed(2) === totalAmount.toFixed(2);
  }

  return false;
};
