const getEnvironmentVariable = (environmentVariable: string): string => {
  const unvalidatedEnvironmentVariable = process.env[environmentVariable];
  if (!unvalidatedEnvironmentVariable) {
    throw new Error(
      `Couldn't find environment variable: ${environmentVariable}`
    );
  } else {
    return unvalidatedEnvironmentVariable;
  }
};

export const config = {
  API_HOST: getEnvironmentVariable("REACT_APP_API_HOST"),
  API: getEnvironmentVariable("REACT_APP_API"),
	GOOGLE_CLIENT_ID: getEnvironmentVariable("REACT_APP_GOOGLE_CLIENT_ID")
};
