import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { io } from "socket.io-client";

import { config } from "../config/environment.config";
import {
	getAccessToken,
	getRefreshToken,
	removeTokens,
	setTokens,
} from "../store/AccessTokenStore";
import { login, refreshTokens, googleLogin } from "../services/AuthService";
import RolType from "../constants/roles";
import { useNavigate } from "react-router-dom";
import { post } from "services/axiosCall";
import {
	AdminSocketType,
	ICompanieOption,
	IDealershipOption,
	IUserData,
	LoginDTO,
	UserCompaniesResponse,
	UserDealershipResponse,
} from "types";
import { axiosInstance } from "services/BaseService";
import { errorToast } from "helpers/toastFunction";
import { useGoogleLogin } from "@react-oauth/google";

interface User {
	id: number;
	email: string;
	roles: number[];
	dealerships: number[];
	dealershipOptions: IDealershipOption[];
	companiesOptions: ICompanieOption[];
}

interface UserCtxState {
	socket: AdminSocketType | null;
	user: User | null;
	isLoading: boolean;
	logIn: (payload: LoginDTO) => Promise<void>;
	logOut: () => void;
}

const UserContext = createContext<UserCtxState>({} as UserCtxState);

export const UserContextProvider: React.FC = ({ children }) => {
	const navigate = useNavigate();

	const user = useRef<User | null>(null);
	const socketRef = useRef<AdminSocketType | null>(null);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<any|null>(null);

	const logInSocial = useGoogleLogin({
		
		onSuccess: codeResponse => {
			setIsLoading(true);
			setError(null);

			return googleLogin(codeResponse.code)
				.then((data) => {
					setTokens(data);
					return loadUserInfo();
				})
				.then(() => {
					user.current?.roles.includes(RolType.ACCOUNTING)
						? navigate("/historial")
						: navigate("");
					return user
				})
				.catch(error => {
					setError(error)
					return error
				})
				.finally(() => setIsLoading(false));
		},
		onError: error => {
			console.error('Error', error)
			setError(error)
		},
		flow: 'auth-code'
	})

	const initSocket = useCallback(() => {
		if (!socketRef.current)
			socketRef.current = io(
				`${config.API_HOST.split("/api/v1/management")[0]}`,
				{
					auth: {
						token: getAccessToken(),
					},
					transports: ["websocket", "polling", "flashsocket"],
					reconnection: true,
					reconnectionDelay: 500,
					reconnectionDelayMax: 50,
				}
			) as AdminSocketType;
	}, []);

	const logOut = useCallback(() => {
		removeTokens();
		user.current = null;
		navigate("/");

		if (socketRef.current) {
			socketRef.current.off();
			socketRef.current.disconnect();
		}
	}, [navigate]);

	const getUserDealershipsOptions = async (): Promise<IDealershipOption[]> => {
		const responseLocals: UserDealershipResponse = await post("/api/filtros", {
			dataCall: { data_query: "locales_citar", data_call: null },
		});

		return responseLocals?.map((item) => ({
			label: item.nombre,
			value: item.id,
			identificador: item.literal,
		}));
	};

	const getUserCompaniesOptions = async (): Promise<ICompanieOption[]> => {
		const responseCompanies: UserCompaniesResponse = await post(
			"/api/filtros",
			{
				dataCall: { data_query: "empresas_existentes", data_call: null },
			}
		);

		return responseCompanies?.map((item) => ({
			label: item.nombre,
			value: item.id,
		}));
	};

	const getUserData = async (): Promise<IUserData | undefined> => {
		try {
			const userData: IUserData = await axiosInstance.get("/users/me");
			return userData;
		} catch (err) {
			errorToast("Error consiguiendo los datos del usuario");
		}
	};

	const loadUserInfo = useCallback(async () => {
		const token = getAccessToken();
		const refreshToken = getRefreshToken();

		if (token) {
			try {
				const userData = await getUserData();

				if (userData) {
					const dealershipOptions = await getUserDealershipsOptions();
					const companiesOptions = await getUserCompaniesOptions();

					user.current = {
						id: userData.id,
						email: userData.email,
						roles: userData.rolIds,
						dealerships: userData.dealershipIds,
						dealershipOptions,
						companiesOptions,
					};

					initSocket();
				}
			} catch (err) {
				logOut();
			}
		} else if (refreshToken) {
			await refreshTokens();
			await loadUserInfo();
		}

		setIsLoading(false);
	}, [initSocket, logOut]);

	const logIn = useCallback(
		async (payload: LoginDTO) => {
			try {
				const response = await login(payload);
				setTokens(response);
				await loadUserInfo();

				user.current?.roles.includes(RolType.ACCOUNTING)
					? navigate("/historial")
					: navigate("");
			} catch (err) {
				throw err;
			}
		},
		[navigate, loadUserInfo]
	);

	useEffect(() => {
		(async () => !user.current && (await loadUserInfo()))();
	}, [user, loadUserInfo]);

	const contextValue: UserCtxState = useMemo(
		() => ({
			socket: socketRef.current,
			user: user.current,
			isLoading,
			logIn,
			logOut,
			logInSocial,
			error,
			hasError: error !== null
		}),
		[isLoading, logIn, logOut]
	);
	return (
		<UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
	);
};

export const useUserContext = () => useContext(UserContext);
